import React from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { GroupStore, GroupAdminStore} from '@seedlang/stores';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { Link } from 'react-router';
import UserSearch from 'components/user_search';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';
import { Permissions } from '@seedlang/models';

const Wrapper = styled.div`

`;

@observer
class AdminsIndex extends React.Component {

  constructor(props) {
    super(props);
    this.loadGroup();

    autorun(() => {
      if (AppUI.userIsLoaded && !AppUI.permissions.canAccessAdmins) {
        AppUI.routeStore.routeToNamed('creator.groups.worksheets.index', {groupId: this.props.params.groupId});
      }
    })
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onAddUser(user) {
    GroupAdminStore.create({data: {groupId: this.props.params.groupId, userId: user.id}}, this.afterChange)
  }

  @autobind afterChange() {
    GroupStore.reloadShow();
  }

  @autobind onDelete(id) {
    GroupAdminStore.destroy({ids: {groupAdminId: id}}, this.afterChange);
  }

  render () {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Admins</legend>
              <UserSearch
                onSubmit={this.onAddUser}
                placeholder='Search by Email'
              />
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Admin Access</th>
                    <th>Has User Access</th>
                    <th>Records Access</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    GroupStore.hasShowData && GroupStore.showData.groupAdmins.map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>
                            <Link
                              to={{name: 'creator.groups.users.edit', params: {groupId: this.props.params.groupId, userId: item.user.id}}}
                              className='underline'
                            >
                              {item.user?.name}
                            </Link>
                          </td>
                          <td>
                            {item.user?.role}
                          </td>
                          <td>
                            <div>
                            <div>
                              <InPlaceCheckbox
                                model="users"
                                field="can_access_admin_posts"
                                value={item.user.canAccessAdminPosts}
                                id={item.user.id}
                                icon="check"
                              >
                                Posts
                              </InPlaceCheckbox>
                            </div>
                              <InPlaceCheckbox
                                model="users"
                                field="can_access_admin_users"
                                value={item.user.canAccessAdminUsers}
                                id={item.user.id}
                                icon="check"
                              >
                                User Management
                              </InPlaceCheckbox>
                            </div>
                            <div>
                              <InPlaceCheckbox
                                model="users"
                                field="can_access_admin_site"
                                value={item.user.canAccessAdminSite}
                                id={item.user.id}
                                icon="check"
                              >
                                Site Setup
                              </InPlaceCheckbox>
                            </div>
                          </td>
                          <td>
                            <InPlaceSelect
                              model="users"
                              id={item.user.id}
                              field="user_data_access_level"
                              options={Permissions.userDataAccessLevelForSelect}
                              value={item.user.userDataAccessLevel}
                              afterChange={this.loadGroup}
                            />
                          </td>
                          <td>
                            <div>
                              <InPlaceCheckbox
                                model="users"
                                field="can_update_records"
                                value={item.user.canUpdateRecords}
                                id={item.user.id}
                                icon="check"
                              >
                                Can Update
                              </InPlaceCheckbox>
                            </div>
                            <div>
                              <InPlaceCheckbox
                                model="users"
                                field="can_delete_records"
                                value={item.user.canDeleteRecords}
                                id={item.user.id}
                                icon="check"
                              >
                                Can Delete
                              </InPlaceCheckbox>
                            </div>
                          </td>
                          <td>
                            <DeleteButton
                              width="50px"
                              onConfirm={() => this.onDelete(item.id)}
                              message="Remove this association?"
                            >
                              <i className='fa fa-times fa-block' />
                            </DeleteButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AdminsIndex);

import React from 'react';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI, ForumUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Breadcrumbs from 'components/breadcrumbs';
import autobind from 'autobind-decorator';
import CommentIndex from 'components/comment/comment_index';
import Spinner from 'components/spinner';
import { Link } from "react-router";
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`

`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbWrapper = styled.div`
  width: ${props => props.width};
`;

const WorksheetLinkWrapper = styled.div`
  margin: -10px 0 20px 0;
  display: flex;
  align-items: center;
`;

const WorksheetLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 16px;
  flex: 1;
  line-height: 20px;
  cursor: pointer;
  margin: 0 0 0 5px;
  .icon-wrapper {
    margin-right: 5px;
  }
  .icon-wrapper, .pin-icon {
    height: 14px;
    width: 14px;
  }
  svg {
    width: 14px;
    fill: #333;
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: 40px;
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

@observer
class ForumThreadShow extends React.Component {
  @observable createdEvent = false;

  constructor(props) {
    super(props);
    ForumUI.onReset();
    ForumUI.loadForum(this.props.params.forumId);
    this.loadForumThread();
    this.disposer = autorun(() => {
      if (!this.createdEvent && ForumUI.hasForumThread && ForumUI.hasForum) {
        this.createdEvent = true;
        AppUI.createEvent('discuss - view thread', {forum_title: ForumUI.forum.title, forum_thread_title: ForumUI.forumThread.titleOrTarget});
      }
    })
  }

  componentWillUnmount() {
    ForumUI.forumThreadStore.clearShowData();
    this.disposer && this.disposer();
  }

  @autobind loadForumThread() {
    ForumUI.loadForumThread(this.props.params.forumThreadId);
  }

  render() {
    if (ForumUI.hasForumThread) {
      return (
        <Wrapper>
          {
            !this.props.params.groupId &&
              <TopBar>
                <BreadcrumbWrapper
                  width="380px"
                >
                  <Breadcrumbs
                    height="50px"
                    margin="0 0 10px 0"
                    breadcrumbs={[
                      {name: 'All Discussions', routeToName: 'forums.index'},
                      {name: ForumUI.forumStore.showData.title, routeToName: "forums.show", routeToParams: {page: 1, forumId: this.props.params.forumId}},
                    ]}
                  />
                </BreadcrumbWrapper>
              </TopBar>
          }
          {
            this.props.params.groupId && ForumUI.forumThread.worksheet &&
              <TopBar>
                <BreadcrumbWrapper>
                  <Breadcrumbs
                    height="50px"
                    margin="0 0 10px 0"
                    breadcrumbs={[
                      {name: 'All Comments', routeToName: 'creator.worksheet.comments', routeToParams: {groupId: this.props.params.groupId}},
                      {name: ForumUI.forumThread.worksheet.name, routeToName: "worksheets.show", routeToParams: {worksheetId: ForumUI.forumThread.worksheet.id, groupId: this.props.params.groupId }},
                    ]}
                  />
                </BreadcrumbWrapper>
              </TopBar>
          }
          {
            this.props.params.groupId && ForumUI.forumThread.exercise && ForumUI.forumThread.exercise.worksheet &&
              <TopBar>
                <BreadcrumbWrapper>
                  <Breadcrumbs
                    height="50px"
                    margin="0 0 10px 0"
                    breadcrumbs={[
                      {name: 'All Comments', routeToName: 'creator.worksheet.comments', routeToParams: {groupId: this.props.params.groupId}},
                      {name: ForumUI.forumThread.exercise.worksheet.name, routeToName: "worksheets.show", routeToParams: {worksheetId: ForumUI.forumThread.exercise.worksheet.id, groupId: this.props.params.groupId }},
                      {name: ForumUI.forumThread.exercise.name, routeToName: "worksheets.exercises.show", routeToParams: {exerciseId: ForumUI.forumThread.exercise.id, worksheetId: ForumUI.forumThread.exercise.worksheet.id, groupId: this.props.params.groupId }},
                    ]}
                  />
                </BreadcrumbWrapper>
              </TopBar>
          }
          <CommentIndex
            hideUserProfile={!AppUI.siteIsDefault}
            groupId={this.props.params.groupId}
            inModal={this.props.inModal}
            post={ForumUI.forumThread}
            showForumThreadPost
            showCommentCount
            user={ForumUI.user}
            commentable={ForumUI.forumThread}
            commentableType="ForumThread"
            forumThreadId={ForumUI.forumThread.id}
            margin="0"
            languageId={ForumUI.forumThread.languageId || AppUI.targetLanguageId}
          />
          {
            AppUI.user.anyAdmin && isPresent(ForumUI.forumThread.sentence) &&
            <AdminLink>
              <Link
                to={{name: 'builder.sentences.edit', params: {sentenceId: ForumUI.forumThread.sentence.id}}}
                target="_blank"
              >
                Admin Link
              </Link>
            </AdminLink>
          }
          {
            AppUI.user.anyAdmin && isPresent(ForumUI.forumThread.word) &&
            <AdminLink>
              <Link
                to={{name: 'builder.words.edit', params: {wordId: ForumUI.forumThread.word.id}}}
                target="_blank"
              >
                Admin Link
              </Link>
            </AdminLink>
          }
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    )
  }
}

export default ErrorBoundary(ForumThreadShow);

// this is ordered by name

const AiLanguages = {
  EN: "English (American)",
  EN2: "English (British)",
  AR: "Arabic",
  BN: "Bengali",
  MY: "Burmese",
  ZH: "Chinese (Simplified)",
  ZH2: "Chinese (Traditional)",
  CS: "Czech",
  NL: "Dutch",
  FR: "French",
  DE: "German",
  IW: "Hebrew",
  HI: "Hindi",
  IN: "Indonesian",
  IT: "Italian",
  JA: "Japanese",
  KO: "Korean",
  MS: "Malay",
  FA: "Persian",
  PL: "Polish",
  PT2: "Portuguese (Brazilian)",
  PT: "Portuguese (European)",
  RU: "Russian",
  ES: "Spanish",
  TL: "Tagalog",
  TH: "Thai",
  TR: "Turkish",
  UR: "Urdu",
  VI: "Vietnamese",
};

export default AiLanguages;

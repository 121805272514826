import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { UserGroupStore, MembershipTypeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import UserGroupCreate from 'pages/builder/user_groups/user_group_create';
import Modal from 'components/modal';
import { AppUI } from '@seedlang/state';
import { Constants } from '@seedlang/constants';
import Spinner from "components/spinner";

const Wrapper = styled.div`
  .text {
    padding: 0 5px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class UsersIndex extends React.Component {
  @observable showCreate = false;

  @computed get exportUrl() {
    if (window.location.href.match('ngrok')) {
      return 'http://localhost:3000/exports/user.csv';
    }
    return '/exports/user.csv';
  }

  constructor(props) {
    super(props);
    MembershipTypeStore.getIndex({filters: {site_id: AppUI.siteId}});
    autorun(() => {
      if (AppUI.userIsLoaded && !AppUI.permissions.canAccessBasicUserData) {
        AppUI.routeStore.routeToNamed('creator.groups.worksheets.index', {groupId: this.props.params.groupId});
      }
    });
  }

  @computed get membershipTypeOptions() {
    const options = MembershipTypeStore.indexData.map(item => ({id: item.id, name: item.name}));
    options.push({id: 'nil', name: 'No Membership'});
    return options;
  }

  render() {
    return (
      <Wrapper>
        {
          this.showCreate &&
            <Modal
              onCloseModal={() => this.showCreate = false}
            >
              <UserGroupCreate />
            </Modal>
        }
        {
            <Filter
              store={UserGroupStore}
              defaultSort="email"
              namespace="patreon-user-index"
              ids={{groupId: this.props.params.groupId}}
              filters={
                [
                  {
                    type: 'text',
                    name: 'email',
                    label: 'email',
                    placeholder: 'Email',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'membership_type_id',
                    options: this.membershipTypeOptions,
                    labelField: 'name',
                    label: 'Membership Tier',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'permanent',
                    label: 'Permanent',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'role',
                    options: Constants.USER_ROLES,
                    label: 'Role',
                    placeholder: 'Role',
                    default: '',
                  },
                ]
              }
            />
        }
        <ButtonRow>
          <button
            className='gray-button'
            onClick={() => this.showCreate = true}
          >
            <i className='fa fa-plus' />
            Add User
          </button>

          {
            AppUI.permissions.canAccessUserEmails && (
              <a href={this.exportUrl} target='_blank' rel="noopener noreferrer">
                <button
                  style={{
                    marginLeft: '10px',
                  }}
                  className='gray-button'
                >
                  <i className='fa fa-angle-right' />
                  Export Users Table
                </button>
              </a>
            )
          }
        </ButtonRow>
        <table
          className="table-wrapper"
          style={{
            marginTop: 10,
          }}
        >
          <thead>
            <tr>
              <th width="40"></th>
              {
                AppUI.permissions.canAccessUserEmails && (
                  <th>Email</th>
                )
              }
              <th>Name</th>
              {
                !AppUI.siteIsDefault &&
                  <th>Membership Type</th>
              }
            </tr>
          </thead>
          <tbody>
          {
            UserGroupStore.requestCounter > 0 &&
              <tr>
                <td colSpan={4}>
                  <Spinner background={AppUI.site.accentColor} />
                </td>
              </tr>
          }
            {
              UserGroupStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'creator.groups.users.edit', params: { groupId: this.props.params.groupId, userId: item.user.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    {
                      AppUI.permissions.canAccessUserEmails && (
                        <td>
                          <div className='text'>
                            {item.user.email}
                          </div>
                        </td>
                      )
                    }
                    <td>
                      <div className='text'>
                        {item.user.name}
                      </div>
                    </td>
                    {
                      !AppUI.siteIsDefault &&
                        <td>
                          <div className='text'>
                            {item.membershipType?.name}
                          </div>
                        </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={UserGroupStore}
          queryStrings={{expanded: true}}
        />
      </Wrapper>
    );
  }
}

export default UsersIndex;

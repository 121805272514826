import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled.div`
  background: #404040;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  z-index: 1;
  max-width: 500px;
  border-radius: 20px;
  margin-top: 80px;
  background: #FFF;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-decoration: center;
  }

`;

const ButtonRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
`;

@observer
class UnpublishedWhiteLabelHome extends React.Component {

  render () {
    return (
      <Wrapper>
        <Backdrop />
        <Container>
          <p>This website is currently under construction and will be live very soon.</p>
          {
            isPresent(AppUI.site.patreonMembershipPage) &&
              <p>If you're looking to become a member of {AppUI.site.teamName}, please check out our current Membership offers on Patreon.</p>
          }
          <ButtonRow>
            {
              isPresent(AppUI.site.patreonMembershipPage) &&
                <Button
                  margin="0 5px 0 0"
                  width="215px"
                  onClick={() => window.location.href = AppUI.site.patreonMembershipPage}
                  background={AppUI.site.accentColor}
                >
                  Become a Member
                </Button>
            }
            <Link
              to={{name: 'onboarding.sign_in'}}
            >
              <Button
                margin="0 0 0 5px"
                width="215px"
                background={AppUI.site.accentColor}
              >
                Sign In for Admins
              </Button>
            </Link>
          </ButtonRow>
        </Container>
      </Wrapper>
    );
  }
}

export default UnpublishedWhiteLabelHome;

import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ConceptStore } from '@seedlang/stores';
import { RouteStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import styled from '@emotion/styled';
import { Constants, Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { keys } from 'lodash';
import Alert from "components/alert";

const Wrapper = styled.div`
  .concept-create {
    select {
      height: 35px;
    }
    input {
      margin-right: 10px;
    }
  }
`;

const Button = styled.div`
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
  background: ${props => props.background};
  color: ${props => props.color};
  margin: 0 10px 10px 0;
  border-radius: 10px;
`;

@observer
class ConceptCreate extends React.Component {
  @observable error = false;
  @observable languageId;
  @observable vocabTriad = false;
  @observable trivia = false;

  constructor (props) {
    super(props);
    this.trivia = this.props.trivia;
  }


  componentDidMount() {
    this.languageId = keys(Constants.LANGUAGE_OPTIONS).indexOf(AppUI.user.languageId) !== -1 ? AppUI.user.languageId : 'DE';
  }

  onClick() {
    const data = Object.assign({name: this.refs.name.value, language_id: this.languageId}, this.props.data);
    if (this.vocabTriad) {
      data['automatic_associations'] = false;
      data['concept_category_id'] = Constants.VOCAB_TRIAD_CONCEPT_CATEGORY_ID;
      data['show_as_concept'] = false;
    }
    if (this.trivia) {
      data['automatic_associations'] = false;
      data['concept_category_id'] = Constants.TRIVIA_CONCEPT_CATEGORY_ID;
      data['show_as_concept'] = false;
    }
    data['grammar_concept'] = this.props.grammarConcept;
    ConceptStore.create({ data: data}, this.afterCreate.bind(this));
    this.refs.name.value = '';
  }

  afterCreate(resp) {
    if (this.props.routeToAfterCreate) {
      RouteStore.routeToNamed(this.props.routeToAfterCreate, Object.assign({}, {conceptId: resp.conceptId}, this.props.routeParams));
    } else if (resp.conceptId && this.props.routeToName) {
      RouteStore.routeToNamed(this.props.routeToName, Object.assign({conceptId: resp.conceptId}, this.props.routeIds))
    } else if (resp.conceptId) {
      RouteStore.routeTo(`/builder/concepts/${resp.conceptId}`);
    } else {
      this.error = true;
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="sentence-create translation-create">
          {
            this.error &&
              <div className='row'>
                <div className="col-xs-12">
                  <div className='alert'>Sorry, there was an error</div>
                </div>
              </div>
          }
          <div className='row'>
            {
              keys(Constants.LANGUAGE_OPTIONS).map((languageId) => {
                return (
                  <Button
                    onClick={() => this.languageId = languageId}
                    background={this.languageId === languageId ? Theme.green : "#CCC"}
                    color={this.languageId === languageId ? "#FFF" : "#333"}
                  >
                    {Constants.LANGUAGE_OPTIONS[languageId]}
                  </Button>
                )
              })
            }
          </div>
          <input
            placeholder={this.props.placeholder || "Enter a concept name"}
            ref="name"
          />
          <div className='row' style={{marginTop: "10px", display: "flex"}}>
            <InPlaceCheckbox
              value={this.vocabTriad}
              onChange={(val) => this.vocabTriad = val}
            >
              Vocab Triad
            </InPlaceCheckbox>
            <InPlaceCheckbox
              value={this.trivia}
              onChange={(val) => this.trivia = val}
              wrapperMargin="0 0 0 10px"
            >
              Trivia
            </InPlaceCheckbox>
          </div>
          {
            this.props.showTreeNodeWarning &&
            <Alert fontSize='12px' margin='10px 0 0 0'>
              If your goal is to create a deck in the tree, please create the concept directly in the tree node.
            </Alert>
          }
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
            style={{
              marginTop: 10,
            }}
          >
            Create
          </button>
        </div>
      </Wrapper>
    );
  }
}

export default ConceptCreate;

import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { SettingsOutlineIcon, CloseIcon, CloseIconRedX } from '@seedlang/icons';
import { Motion, spring } from 'react-motion';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify, isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
`;

const ModalBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => props.background};
  z-index: ${Theme.z["modal-background"]};
`;

const ModalWrapper = styled.div`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  position: fixed;
  background: ${props => props.background};
  border-radius: 10px;
  z-index: ${Theme.z["modal-foreground"]};
  overflow-x: hidden;
  overflow-y: ${props => props.overflowY};
  margin: 0 auto;
  margin-top: ${(props) => pixify(props.marginTop)};
  left: 0;
  right: 0;
`;

const CloseIconWrapper = styled.div`
  width: 20px;
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  cursor: pointer;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  z-index: 99;
  svg {
    fill: ${props => props.iconFill};
    width: 20px;
  }
`;

const SettingsIconWrapper = styled.div`
  width: 20px;
  position: absolute;
  top: ${props => props.top};
  left: 20px;
  cursor: pointer;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  svg {
    fill: ${props => props.iconFill};
    width: 20px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  background: ${props => props.background || '#FFF'};
  width: 100%;
  height: ${props => props.height};
  padding: ${props => props.padding};
  overflow: ${props => props.overflow};
  max-height: ${(props) => props.maxHeight};
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  padding: ${props => props.padding};
  height: ${props => props.height};
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    width: 100%;
    color: #FFF !important;
    margin: 0;
    text-align: center;
    font-size: 18px;
  }
`;

@observer
class Modal extends React.Component {
  @observable scale = this.props.animate ? 0.3 : 1;

  @computed get iconTop() {
    if (this.props.iconTop) {
      return this.props.iconTop;
    }
    return isPresent(this.props.title) ? "0px" : "40px";
  }

  @computed get padding() {
    if (this.props.padding) {
      return this.props.padding;
    } else if (AppUI.layout.isMobile) {
      return "10px";
    }
    return "20px";
  }

  @computed get background() {
    if (this.props.gradient) {
      return "linear-gradient(-134deg,#3BD7C5 0%,#0DAFF6 100%)";
    }
    return this.props.background;
  }

  static defaultProps = {
    top: "80px",
    width: "660px",
    modalBackground: "rgba(0, 0, 0, 0.8)",
    background: "#FFF",
    iconFill: "#FFF",
  }

  componentDidMount() {
    this.scale = 1;
  }

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.scale, {stiffness: 300, damping: 18}),
        }}
      >
        {
          ({scale}) =>
            <Wrapper>
              <ModalBackground
                onClick={this.props.onCloseModal}
                background={this.props.modalBackground}
              />
              <ModalWrapper
                background={this.background}
                top={this.props.top}
                width={pixify(this.props.width)}
                maxWidth={this.props.maxWidth}
                height={pixify(this.props.height)}
                overflowY={this.props.contentShouldScroll ? 'hidden' : 'auto'}
                style={Object.assign({}, this.props.modalStyle, {
                  transform: `scale(${scale})`,
                })}
                marginTop={this.props.marginTop}
              >
                <Header
                  padding={isPresent(this.props.title) ? "20px" : "0px"}
                  height={isPresent(this.props.title) ? "60px" : "0px"}
                >
                  {
                    this.props.onClickGearIcon &&
                      <SettingsIconWrapper
                        top={this.iconTop}
                        iconFill={this.props.iconFill}
                      >
                        <SettingsOutlineIcon
                          onClick={this.props.onClickGearIcon}
                        />
                      </SettingsIconWrapper>
                  }
                  {
                    this.props.onCloseModal && !this.props.hideCloseIcon &&
                      <CloseIconWrapper
                        top={this.iconTop}
                        right={this.props.iconRight ? this.props.iconRight : "20px"}
                        iconFill={this.props.iconFill}
                      >
                        {
                          this.props.showRedX &&
                            <CloseIconRedX
                              onClick={this.props.onCloseModal}
                            />
                        }
                        {
                          !this.props.showRedX &&
                            <CloseIcon
                              onClick={this.props.onCloseModal}
                            />
                        }
                      </CloseIconWrapper>
                  }
                  {
                    this.props.title &&
                      <h3 style={{marginRight: '30px'}}>{this.props.title}</h3>
                  }
                </Header>
                <ModalContent
                  background={this.props.contentBackground}
                  height={this.props.title && this.props.contentShouldScroll ? "calc(100% - 60px)" : "100%"}
                  padding={this.padding}
                  overflow={this.props.contentShouldScroll ? 'auto' : null}
                  maxHeight={pixify(this.props.maxHeight)}
                >
                  {this.props.children}
                </ModalContent>
              </ModalWrapper>
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default Modal;

import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ConceptStore, AutomaticConceptStore, ConceptWordStore } from '@seedlang/stores';
import ModalWrapper from 'components/hoc/modal_wrapper';
import { sortBy } from 'lodash';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import cx from 'classnames';
import styled from '@emotion/styled';
import WordCard from 'components/word_card/word_card';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import { AppUI } from "@seedlang/state";

const Wrapper = styled.div`
  width: 100%;
`;

const SearchBar = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
  i {
    position: absolute;
    color: #CCC;
    top: 8px;
    left: 8px;
    z-index: 99;
  }
  input {
    padding: 10px 10px 10px 30px!important;
    height: 32px!important;
    border: 1px solid #CCC;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

@observer
class ConceptWordIndex extends React.Component {
  @observable concepts = [];
  @observable searchQuery = null;

  componentDidMount() {
    this.getConcepts();
    if (AppUI.siteIsDefault) {
      this.concepts = this.props.word.concepts;
    } else {
      this.concepts = this.props.word.concepts.filter(item => item.groupId === AppUI.site.groupId);
    }
  }

  componentWillUnmount() {
    AutomaticConceptStore.setPage(1);
    AutomaticConceptStore.clearFilter();
    AutomaticConceptStore.clearIndexData();
  }

  onCreate(concept) {
    this.concepts.push(concept);
    ConceptWordStore.create({ data: { concept_id: concept.id, word_id: this.props.word.id } }, this.props.onChange);
  }

  onDelete(conceptId) {
    this.concepts = this.concepts.filter(item => item.id !== conceptId);
    ConceptWordStore.destroy({ ids: { concept_id: conceptId, word_id: this.props.word.id } }, this.props.onChange);
  }

  onClick(concept) {
    if (this.hasConcept(concept.id)) {
      this.onDelete(concept.id);
    } else {
      this.onCreate(concept);
    }
  }

  hasConcept(conceptId) {
    return isPresent(this.concepts.find(item => item.id === conceptId));
  }

  @autobind updateSearchQuery(val) {
    this.searchQuery = val;
    this.getConcepts();
  }

  @autobind getConcepts() {
    if (AppUI.siteIsDefault) {
      AutomaticConceptStore.getIndex({ limit: 10, filters: {
          language_id: this.props.word.languageId,
          name: isPresent(this.searchQuery) ? `~${this.searchQuery}~` : null,
        }});
    } else {
      ConceptStore.getIndex({limit: 10, filters: {
          group_id: this.props.groupId,
          name: isPresent(this.searchQuery) ? `~${this.searchQuery}~` : null
      }});
    }
  }

  @computed get conceptIndexData() {
    if (AppUI.siteIsDefault) {
      return AutomaticConceptStore.indexData;
    } else {
      return ConceptStore.indexData;
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="row">
          {
            !this.props.hideWordCard &&
              <div className="col-xs-6">
                <h5>Word Card</h5>
                <WordCard
                  word={this.props.word}
                  wordAssociation={this.props.wordAssociation}
                  sentence={this.props.sentence}
                  backend
                  height="auto"
                  width="400px"
                />
              </div>
          }
          <div className={this.props.hideWordCard ? "col-xs-12" : "col-xs-6"}>
            <h5>{isPresent(this.props.title) ? this.props.title : 'Concepts'}</h5>
            {
              <div style={{marginBottom: 10}}>
                <ul className="small-list">
                  {
                  sortBy(this.concepts, ['name']).map((item) => {
                    return (
                      <li key={item.id}>
                        <Link
                          to={{ name: 'builder.concepts.edit', params: { conceptId: item.id } }}
                          className='underline'
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })
                }
                </ul>
              </div>
            }
            <SearchBar>
              <i className='fa fa-search' />
              <InPlaceText
                show
                onChange={this.updateSearchQuery}
              />
            </SearchBar>
            {
              isPresent(this.conceptIndexData) &&
                <table className="table-wrapper admin-table">
                  <thead>
                    <tr>
                      <th width="40"></th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.conceptIndexData.map((item) => {
                        return (
                          <tr
                            key={item.id}
                          >
                            <td>
                              <i
                                className={cx("fa", "fa-big", "fa-on-click", "fa-check-circle", {disabled: !this.hasConcept(item.id)})}
                                onClick={this.onClick.bind(this, item)}
                              />
                            </td>
                            <td>
                              {item.name}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
            }
            <Paginator
              store={AutomaticConceptStore}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ModalWrapper(ConceptWordIndex, {width: "850px", height: "740px"});

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AddFatIcon", {
  enumerable: true,
  get: function get() {
    return _add_fat_icon["default"];
  }
});
Object.defineProperty(exports, "AddIcon", {
  enumerable: true,
  get: function get() {
    return _add_icon["default"];
  }
});
Object.defineProperty(exports, "AlertIcon", {
  enumerable: true,
  get: function get() {
    return _alert_icon["default"];
  }
});
Object.defineProperty(exports, "ArrowIcon", {
  enumerable: true,
  get: function get() {
    return _arrow_icon["default"];
  }
});
Object.defineProperty(exports, "ArrowLeftIcon", {
  enumerable: true,
  get: function get() {
    return _arrow_left_icon["default"];
  }
});
Object.defineProperty(exports, "BadgeIcon", {
  enumerable: true,
  get: function get() {
    return _badge_icon["default"];
  }
});
Object.defineProperty(exports, "BellIcon", {
  enumerable: true,
  get: function get() {
    return _bell_icon["default"];
  }
});
Object.defineProperty(exports, "BellOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _bell_outline_icon["default"];
  }
});
Object.defineProperty(exports, "BigSmileFaceIcon", {
  enumerable: true,
  get: function get() {
    return _big_smile_face_icon["default"];
  }
});
Object.defineProperty(exports, "BookIcon", {
  enumerable: true,
  get: function get() {
    return _book_icon["default"];
  }
});
Object.defineProperty(exports, "BookmarkIcon", {
  enumerable: true,
  get: function get() {
    return _bookmark_icon["default"];
  }
});
Object.defineProperty(exports, "BookmarkOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _bookmark_outline_icon["default"];
  }
});
Object.defineProperty(exports, "BrainIcon", {
  enumerable: true,
  get: function get() {
    return _brain_icon["default"];
  }
});
Object.defineProperty(exports, "BrokenCircleIcon", {
  enumerable: true,
  get: function get() {
    return _broken_circle_icon["default"];
  }
});
Object.defineProperty(exports, "BrokenIcon", {
  enumerable: true,
  get: function get() {
    return _broken_icon["default"];
  }
});
Object.defineProperty(exports, "CalendarIcon", {
  enumerable: true,
  get: function get() {
    return _calendar_icon["default"];
  }
});
Object.defineProperty(exports, "CardsIcon", {
  enumerable: true,
  get: function get() {
    return _cards_icon["default"];
  }
});
Object.defineProperty(exports, "CaretRightCircleIcon", {
  enumerable: true,
  get: function get() {
    return _caret_right_circle_icon["default"];
  }
});
Object.defineProperty(exports, "CaseIcon", {
  enumerable: true,
  get: function get() {
    return _case_icon["default"];
  }
});
Object.defineProperty(exports, "ChatIcon", {
  enumerable: true,
  get: function get() {
    return _chat_icon["default"];
  }
});
Object.defineProperty(exports, "ChatIconWithCount", {
  enumerable: true,
  get: function get() {
    return _chat_icon_with_count["default"];
  }
});
Object.defineProperty(exports, "ChatOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _chat_outline_icon["default"];
  }
});
Object.defineProperty(exports, "ChatOutlineIcon2", {
  enumerable: true,
  get: function get() {
    return _chat_outline_icon_["default"];
  }
});
Object.defineProperty(exports, "CheckIcon", {
  enumerable: true,
  get: function get() {
    return _check_icon["default"];
  }
});
Object.defineProperty(exports, "CheckWithCircleIcon", {
  enumerable: true,
  get: function get() {
    return _check_with_circle_icon["default"];
  }
});
Object.defineProperty(exports, "CheckmarkOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _checkmark_outline_icon["default"];
  }
});
Object.defineProperty(exports, "ClassroomIcon", {
  enumerable: true,
  get: function get() {
    return _classroom_icon["default"];
  }
});
Object.defineProperty(exports, "ClipboardIcon", {
  enumerable: true,
  get: function get() {
    return _clipboard_icon["default"];
  }
});
Object.defineProperty(exports, "ClockFaceIcon", {
  enumerable: true,
  get: function get() {
    return _clock_face_icon["default"];
  }
});
Object.defineProperty(exports, "ClockIcon", {
  enumerable: true,
  get: function get() {
    return _clock_icon["default"];
  }
});
Object.defineProperty(exports, "ClockfaceIcon", {
  enumerable: true,
  get: function get() {
    return _clockface_icon["default"];
  }
});
Object.defineProperty(exports, "CloseIcon", {
  enumerable: true,
  get: function get() {
    return _close_icon["default"];
  }
});
Object.defineProperty(exports, "CloseIconRedX", {
  enumerable: true,
  get: function get() {
    return _close_icon_red_x["default"];
  }
});
Object.defineProperty(exports, "CloseWithCircleIcon", {
  enumerable: true,
  get: function get() {
    return _close_with_circle_icon["default"];
  }
});
Object.defineProperty(exports, "CommunityBadgeIcon", {
  enumerable: true,
  get: function get() {
    return _community_badge_icon["default"];
  }
});
Object.defineProperty(exports, "ComparisonIcon", {
  enumerable: true,
  get: function get() {
    return _comparison_icon["default"];
  }
});
Object.defineProperty(exports, "CrownIcon", {
  enumerable: true,
  get: function get() {
    return _crown_icon["default"];
  }
});
Object.defineProperty(exports, "CubeIcon", {
  enumerable: true,
  get: function get() {
    return _cube_icon["default"];
  }
});
Object.defineProperty(exports, "DeckIcon", {
  enumerable: true,
  get: function get() {
    return _deck_icon["default"];
  }
});
Object.defineProperty(exports, "DeckmasterIcon", {
  enumerable: true,
  get: function get() {
    return _deckmaster_icon["default"];
  }
});
Object.defineProperty(exports, "DerDieDasIcon", {
  enumerable: true,
  get: function get() {
    return _der_die_das_icon["default"];
  }
});
Object.defineProperty(exports, "DictionaryIcon", {
  enumerable: true,
  get: function get() {
    return _dictionary_icon["default"];
  }
});
Object.defineProperty(exports, "DotIconWrapper", {
  enumerable: true,
  get: function get() {
    return _dot_icon_wrapper["default"];
  }
});
Object.defineProperty(exports, "DoubleArrowIcon", {
  enumerable: true,
  get: function get() {
    return _double_arrow_icon["default"];
  }
});
Object.defineProperty(exports, "DownIcon", {
  enumerable: true,
  get: function get() {
    return _down_icon["default"];
  }
});
Object.defineProperty(exports, "EditIcon", {
  enumerable: true,
  get: function get() {
    return _edit_icon["default"];
  }
});
Object.defineProperty(exports, "EmblemIcon", {
  enumerable: true,
  get: function get() {
    return _emblem_icon["default"];
  }
});
Object.defineProperty(exports, "EraseIcon", {
  enumerable: true,
  get: function get() {
    return _erase_icon["default"];
  }
});
Object.defineProperty(exports, "ExclamationIcon", {
  enumerable: true,
  get: function get() {
    return _exclamation_icon["default"];
  }
});
Object.defineProperty(exports, "ExpandIcon", {
  enumerable: true,
  get: function get() {
    return _expand_icon["default"];
  }
});
Object.defineProperty(exports, "EyeIcon", {
  enumerable: true,
  get: function get() {
    return _eye_icon["default"];
  }
});
Object.defineProperty(exports, "FaqIcon", {
  enumerable: true,
  get: function get() {
    return _faq_icon["default"];
  }
});
Object.defineProperty(exports, "FeedIcon", {
  enumerable: true,
  get: function get() {
    return _feed_icon["default"];
  }
});
Object.defineProperty(exports, "FilterIcon", {
  enumerable: true,
  get: function get() {
    return _filter_icon["default"];
  }
});
Object.defineProperty(exports, "FlagAbIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ab_icon["default"];
  }
});
Object.defineProperty(exports, "FlagArIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ar_icon["default"];
  }
});
Object.defineProperty(exports, "FlagBgIcon", {
  enumerable: true,
  get: function get() {
    return _flag_bg_icon["default"];
  }
});
Object.defineProperty(exports, "FlagBrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_br_icon["default"];
  }
});
Object.defineProperty(exports, "FlagBsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_bs_icon["default"];
  }
});
Object.defineProperty(exports, "FlagCaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ca_icon["default"];
  }
});
Object.defineProperty(exports, "FlagCsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_cs_icon["default"];
  }
});
Object.defineProperty(exports, "FlagDeIcon", {
  enumerable: true,
  get: function get() {
    return _flag_de_icon["default"];
  }
});
Object.defineProperty(exports, "FlagEnIcon", {
  enumerable: true,
  get: function get() {
    return _flag_en_icon["default"];
  }
});
Object.defineProperty(exports, "FlagEoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_eo_icon["default"];
  }
});
Object.defineProperty(exports, "FlagEsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_es_icon["default"];
  }
});
Object.defineProperty(exports, "FlagFaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_fa_icon["default"];
  }
});
Object.defineProperty(exports, "FlagFjIcon", {
  enumerable: true,
  get: function get() {
    return _flag_fj_icon["default"];
  }
});
Object.defineProperty(exports, "FlagFoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_fo_icon["default"];
  }
});
Object.defineProperty(exports, "FlagFrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_fr_icon["default"];
  }
});
Object.defineProperty(exports, "FlagGdIcon", {
  enumerable: true,
  get: function get() {
    return _flag_gd_icon["default"];
  }
});
Object.defineProperty(exports, "FlagGlIcon", {
  enumerable: true,
  get: function get() {
    return _flag_gl_icon["default"];
  }
});
Object.defineProperty(exports, "FlagHaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ha_icon["default"];
  }
});
Object.defineProperty(exports, "FlagHrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_hr_icon["default"];
  }
});
Object.defineProperty(exports, "FlagHuIcon", {
  enumerable: true,
  get: function get() {
    return _flag_hu_icon["default"];
  }
});
Object.defineProperty(exports, "FlagInIcon", {
  enumerable: true,
  get: function get() {
    return _flag_in_icon["default"];
  }
});
Object.defineProperty(exports, "FlagItIcon", {
  enumerable: true,
  get: function get() {
    return _flag_it_icon["default"];
  }
});
Object.defineProperty(exports, "FlagJaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ja_icon["default"];
  }
});
Object.defineProperty(exports, "FlagKkIcon", {
  enumerable: true,
  get: function get() {
    return _flag_kk_icon["default"];
  }
});
Object.defineProperty(exports, "FlagKoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ko_icon["default"];
  }
});
Object.defineProperty(exports, "FlagKuIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ku_icon["default"];
  }
});
Object.defineProperty(exports, "FlagLaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_la_icon["default"];
  }
});
Object.defineProperty(exports, "FlagMlIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ml_icon["default"];
  }
});
Object.defineProperty(exports, "FlagMrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_mr_icon["default"];
  }
});
Object.defineProperty(exports, "FlagMsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ms_icon["default"];
  }
});
Object.defineProperty(exports, "FlagNlIcon", {
  enumerable: true,
  get: function get() {
    return _flag_nl_icon["default"];
  }
});
Object.defineProperty(exports, "FlagOrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_or_icon["default"];
  }
});
Object.defineProperty(exports, "FlagPaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_pa_icon["default"];
  }
});
Object.defineProperty(exports, "FlagPlIcon", {
  enumerable: true,
  get: function get() {
    return _flag_pl_icon["default"];
  }
});
Object.defineProperty(exports, "FlagPsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ps_icon["default"];
  }
});
Object.defineProperty(exports, "FlagPtIcon", {
  enumerable: true,
  get: function get() {
    return _flag_pt_icon["default"];
  }
});
Object.defineProperty(exports, "FlagRoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ro_icon["default"];
  }
});
Object.defineProperty(exports, "FlagRuIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ru_icon["default"];
  }
});
Object.defineProperty(exports, "FlagRwIcon", {
  enumerable: true,
  get: function get() {
    return _flag_rw_icon["default"];
  }
});
Object.defineProperty(exports, "FlagShIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sh_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSiIcon", {
  enumerable: true,
  get: function get() {
    return _flag_si_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSmIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sm_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSqIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sq_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sr_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSvIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sv_icon["default"];
  }
});
Object.defineProperty(exports, "FlagSwIcon", {
  enumerable: true,
  get: function get() {
    return _flag_sw_icon["default"];
  }
});
Object.defineProperty(exports, "FlagTaIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ta_icon["default"];
  }
});
Object.defineProperty(exports, "FlagTeIcon", {
  enumerable: true,
  get: function get() {
    return _flag_te_icon["default"];
  }
});
Object.defineProperty(exports, "FlagTgIcon", {
  enumerable: true,
  get: function get() {
    return _flag_tg_icon["default"];
  }
});
Object.defineProperty(exports, "FlagTkIcon", {
  enumerable: true,
  get: function get() {
    return _flag_tk_icon["default"];
  }
});
Object.defineProperty(exports, "FlagTrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_tr_icon["default"];
  }
});
Object.defineProperty(exports, "FlagUkIcon", {
  enumerable: true,
  get: function get() {
    return _flag_uk_icon["default"];
  }
});
Object.defineProperty(exports, "FlagUrIcon", {
  enumerable: true,
  get: function get() {
    return _flag_ur_icon["default"];
  }
});
Object.defineProperty(exports, "FlagUsIcon", {
  enumerable: true,
  get: function get() {
    return _flag_us_icon["default"];
  }
});
Object.defineProperty(exports, "FlagViIcon", {
  enumerable: true,
  get: function get() {
    return _flag_vi_icon["default"];
  }
});
Object.defineProperty(exports, "FlagWoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_wo_icon["default"];
  }
});
Object.defineProperty(exports, "FlagYoIcon", {
  enumerable: true,
  get: function get() {
    return _flag_yo_icon["default"];
  }
});
Object.defineProperty(exports, "FlagZhIcon", {
  enumerable: true,
  get: function get() {
    return _flag_zh_icon["default"];
  }
});
Object.defineProperty(exports, "FlameIcon", {
  enumerable: true,
  get: function get() {
    return _flame_icon["default"];
  }
});
Object.defineProperty(exports, "GenderIcon", {
  enumerable: true,
  get: function get() {
    return _gender_icon["default"];
  }
});
Object.defineProperty(exports, "GraphCircleIcon", {
  enumerable: true,
  get: function get() {
    return _graph_circle_icon["default"];
  }
});
Object.defineProperty(exports, "GraphIcon", {
  enumerable: true,
  get: function get() {
    return _graph_icon["default"];
  }
});
Object.defineProperty(exports, "GraphOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _graph_outline_icon["default"];
  }
});
Object.defineProperty(exports, "GroupIcon", {
  enumerable: true,
  get: function get() {
    return _group_icon["default"];
  }
});
Object.defineProperty(exports, "HeartDeIcon", {
  enumerable: true,
  get: function get() {
    return _heart_de_icon["default"];
  }
});
Object.defineProperty(exports, "HelpIcon", {
  enumerable: true,
  get: function get() {
    return _help_icon["default"];
  }
});
Object.defineProperty(exports, "HomeIcon", {
  enumerable: true,
  get: function get() {
    return _home_icon["default"];
  }
});
Object.defineProperty(exports, "InfoIcon", {
  enumerable: true,
  get: function get() {
    return _info_icon["default"];
  }
});
Object.defineProperty(exports, "JapaneseCharacterIcon", {
  enumerable: true,
  get: function get() {
    return _japanese_character_icon["default"];
  }
});
Object.defineProperty(exports, "JigsawIcon", {
  enumerable: true,
  get: function get() {
    return _jigsaw_icon["default"];
  }
});
Object.defineProperty(exports, "KeyIcon", {
  enumerable: true,
  get: function get() {
    return _key_icon["default"];
  }
});
Object.defineProperty(exports, "KeyboardIcon", {
  enumerable: true,
  get: function get() {
    return _keyboard_icon["default"];
  }
});
Object.defineProperty(exports, "LanguageIcon", {
  enumerable: true,
  get: function get() {
    return _language_icon["default"];
  }
});
Object.defineProperty(exports, "LearnedIcon", {
  enumerable: true,
  get: function get() {
    return _learned_icon["default"];
  }
});
Object.defineProperty(exports, "LeftIcon", {
  enumerable: true,
  get: function get() {
    return _left_icon["default"];
  }
});
Object.defineProperty(exports, "LightbulbIcon", {
  enumerable: true,
  get: function get() {
    return _lightbulb_icon["default"];
  }
});
Object.defineProperty(exports, "LightbulbOffIcon", {
  enumerable: true,
  get: function get() {
    return _lightbulb_off_icon["default"];
  }
});
Object.defineProperty(exports, "LightbulbOnIcon", {
  enumerable: true,
  get: function get() {
    return _lightbulb_on_icon["default"];
  }
});
Object.defineProperty(exports, "LightbulbOnSolidIcon", {
  enumerable: true,
  get: function get() {
    return _lightbulb_on_solid_icon["default"];
  }
});
Object.defineProperty(exports, "LightningBoltIcon", {
  enumerable: true,
  get: function get() {
    return _lightning_bolt_icon["default"];
  }
});
Object.defineProperty(exports, "ListIcon", {
  enumerable: true,
  get: function get() {
    return _list_icon["default"];
  }
});
Object.defineProperty(exports, "ListWithBulletIcon", {
  enumerable: true,
  get: function get() {
    return _list_with_bullet_icon["default"];
  }
});
Object.defineProperty(exports, "LockIcon", {
  enumerable: true,
  get: function get() {
    return _lock_icon["default"];
  }
});
Object.defineProperty(exports, "MagnifyWordsIcon", {
  enumerable: true,
  get: function get() {
    return _magnify_words_icon["default"];
  }
});
Object.defineProperty(exports, "Membership1LeafIcon", {
  enumerable: true,
  get: function get() {
    return _membership_1leaf_icon["default"];
  }
});
Object.defineProperty(exports, "Membership2LeafIcon", {
  enumerable: true,
  get: function get() {
    return _membership_2leaf_icon["default"];
  }
});
Object.defineProperty(exports, "Membership3LeafIcon", {
  enumerable: true,
  get: function get() {
    return _membership_3leaf_icon["default"];
  }
});
Object.defineProperty(exports, "MembershipTree1Icon", {
  enumerable: true,
  get: function get() {
    return _membership_tree1_icon["default"];
  }
});
Object.defineProperty(exports, "MembershipTree2Icon", {
  enumerable: true,
  get: function get() {
    return _membership_tree2_icon["default"];
  }
});
Object.defineProperty(exports, "MenuIcon", {
  enumerable: true,
  get: function get() {
    return _menu_icon["default"];
  }
});
Object.defineProperty(exports, "MicrophoneBadgeIcon", {
  enumerable: true,
  get: function get() {
    return _microphone_badge_icon["default"];
  }
});
Object.defineProperty(exports, "MicrophoneIcon", {
  enumerable: true,
  get: function get() {
    return _microphone_icon["default"];
  }
});
Object.defineProperty(exports, "MinusIcon", {
  enumerable: true,
  get: function get() {
    return _minus_icon["default"];
  }
});
Object.defineProperty(exports, "MultipleChoiceIcon", {
  enumerable: true,
  get: function get() {
    return _multiple_choice_icon["default"];
  }
});
Object.defineProperty(exports, "NeutralFaceIcon", {
  enumerable: true,
  get: function get() {
    return _neutral_face_icon["default"];
  }
});
Object.defineProperty(exports, "NoMicrophoneIcon", {
  enumerable: true,
  get: function get() {
    return _no_microphone_icon["default"];
  }
});
Object.defineProperty(exports, "NoteIcon", {
  enumerable: true,
  get: function get() {
    return _note_icon["default"];
  }
});
Object.defineProperty(exports, "NotificationsIcon", {
  enumerable: true,
  get: function get() {
    return _notifications_icon["default"];
  }
});
Object.defineProperty(exports, "NumbersIcon", {
  enumerable: true,
  get: function get() {
    return _numbers_icon["default"];
  }
});
Object.defineProperty(exports, "NumbersOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _numbers_outline_icon["default"];
  }
});
Object.defineProperty(exports, "PastDueIcon", {
  enumerable: true,
  get: function get() {
    return _past_due_icon["default"];
  }
});
Object.defineProperty(exports, "PatreonIcon", {
  enumerable: true,
  get: function get() {
    return _patreon_icon["default"];
  }
});
Object.defineProperty(exports, "PencilIcon", {
  enumerable: true,
  get: function get() {
    return _pencil_icon["default"];
  }
});
Object.defineProperty(exports, "PencilIconThick", {
  enumerable: true,
  get: function get() {
    return _pencil_icon_thick["default"];
  }
});
Object.defineProperty(exports, "PencilWithCircleIcon", {
  enumerable: true,
  get: function get() {
    return _pencil_with_circle_icon["default"];
  }
});
Object.defineProperty(exports, "PinIcon", {
  enumerable: true,
  get: function get() {
    return _pin_icon["default"];
  }
});
Object.defineProperty(exports, "PlayIcon", {
  enumerable: true,
  get: function get() {
    return _play_icon["default"];
  }
});
Object.defineProperty(exports, "PluralIcon", {
  enumerable: true,
  get: function get() {
    return _plural_icon["default"];
  }
});
Object.defineProperty(exports, "PluralOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _plural_outline_icon["default"];
  }
});
Object.defineProperty(exports, "PlusIcon", {
  enumerable: true,
  get: function get() {
    return _plus_icon["default"];
  }
});
Object.defineProperty(exports, "ProfileIcon", {
  enumerable: true,
  get: function get() {
    return _profile_icon["default"];
  }
});
Object.defineProperty(exports, "ProgressBarEndIcon", {
  enumerable: true,
  get: function get() {
    return _progress_bar_end_icon["default"];
  }
});
Object.defineProperty(exports, "QuestionIcon", {
  enumerable: true,
  get: function get() {
    return _question_icon["default"];
  }
});
Object.defineProperty(exports, "RecordingIcon", {
  enumerable: true,
  get: function get() {
    return _recording_icon["default"];
  }
});
Object.defineProperty(exports, "ReduceIcon", {
  enumerable: true,
  get: function get() {
    return _reduce_icon["default"];
  }
});
Object.defineProperty(exports, "RefreshIcon", {
  enumerable: true,
  get: function get() {
    return _refresh_icon["default"];
  }
});
Object.defineProperty(exports, "RefreshThickIcon", {
  enumerable: true,
  get: function get() {
    return _refresh_thick_icon["default"];
  }
});
Object.defineProperty(exports, "RegularVerbIcon", {
  enumerable: true,
  get: function get() {
    return _regular_verb_icon["default"];
  }
});
Object.defineProperty(exports, "ReplyIcon", {
  enumerable: true,
  get: function get() {
    return _reply_icon["default"];
  }
});
Object.defineProperty(exports, "RevealSubtitlesIcon", {
  enumerable: true,
  get: function get() {
    return _reveal_subtitles_icon["default"];
  }
});
Object.defineProperty(exports, "RightIcon", {
  enumerable: true,
  get: function get() {
    return _right_icon["default"];
  }
});
Object.defineProperty(exports, "RotateIcon", {
  enumerable: true,
  get: function get() {
    return _rotate_icon["default"];
  }
});
Object.defineProperty(exports, "SearchWordsIcon", {
  enumerable: true,
  get: function get() {
    return _search_words_icon["default"];
  }
});
Object.defineProperty(exports, "SeedlangIcon", {
  enumerable: true,
  get: function get() {
    return _seedlang_icon["default"];
  }
});
Object.defineProperty(exports, "SeedlangIconWithCircle", {
  enumerable: true,
  get: function get() {
    return _seedlang_icon_with_circle["default"];
  }
});
Object.defineProperty(exports, "SeedlangOutlineBoldIcon", {
  enumerable: true,
  get: function get() {
    return _seedlang_outline_bold_icon["default"];
  }
});
Object.defineProperty(exports, "SeedlangOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _seedlang_outline_icon["default"];
  }
});
Object.defineProperty(exports, "SeedlangProWithCrown", {
  enumerable: true,
  get: function get() {
    return _seedlang_pro_with_crown["default"];
  }
});
Object.defineProperty(exports, "SendIcon", {
  enumerable: true,
  get: function get() {
    return _send_icon["default"];
  }
});
Object.defineProperty(exports, "SentencewriterIcon", {
  enumerable: true,
  get: function get() {
    return _sentencewriter_icon["default"];
  }
});
Object.defineProperty(exports, "SettingsIcon", {
  enumerable: true,
  get: function get() {
    return _settings_icon["default"];
  }
});
Object.defineProperty(exports, "SettingsOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _settings_outline_icon["default"];
  }
});
Object.defineProperty(exports, "ShakespeareIcon", {
  enumerable: true,
  get: function get() {
    return _shakespeare_icon["default"];
  }
});
Object.defineProperty(exports, "ShortcutIcon", {
  enumerable: true,
  get: function get() {
    return _shortcut_icon["default"];
  }
});
Object.defineProperty(exports, "SmileFaceIcon", {
  enumerable: true,
  get: function get() {
    return _smile_face_icon["default"];
  }
});
Object.defineProperty(exports, "SmileIcon", {
  enumerable: true,
  get: function get() {
    return _smile_icon["default"];
  }
});
Object.defineProperty(exports, "SparkleIcon", {
  enumerable: true,
  get: function get() {
    return _sparkle_icon["default"];
  }
});
Object.defineProperty(exports, "SpeakingIcon", {
  enumerable: true,
  get: function get() {
    return _speaking_icon["default"];
  }
});
Object.defineProperty(exports, "StarIcon", {
  enumerable: true,
  get: function get() {
    return _star_icon["default"];
  }
});
Object.defineProperty(exports, "StarOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _star_outline_icon["default"];
  }
});
Object.defineProperty(exports, "StarOutlineThinIcon", {
  enumerable: true,
  get: function get() {
    return _star_outline_thin_icon["default"];
  }
});
Object.defineProperty(exports, "StarWithCircle2Icon", {
  enumerable: true,
  get: function get() {
    return _star_with_circle2_icon["default"];
  }
});
Object.defineProperty(exports, "StarWithCircleIcon", {
  enumerable: true,
  get: function get() {
    return _star_with_circle_icon["default"];
  }
});
Object.defineProperty(exports, "StopIcon", {
  enumerable: true,
  get: function get() {
    return _stop_icon["default"];
  }
});
Object.defineProperty(exports, "StreakIcon", {
  enumerable: true,
  get: function get() {
    return _streak_icon["default"];
  }
});
Object.defineProperty(exports, "SwapIcon", {
  enumerable: true,
  get: function get() {
    return _swap_icon["default"];
  }
});
Object.defineProperty(exports, "TableIcon", {
  enumerable: true,
  get: function get() {
    return _table_icon["default"];
  }
});
Object.defineProperty(exports, "TargetIcon", {
  enumerable: true,
  get: function get() {
    return _target_icon["default"];
  }
});
Object.defineProperty(exports, "ThreeCardsIcon", {
  enumerable: true,
  get: function get() {
    return _three_cards_icon["default"];
  }
});
Object.defineProperty(exports, "ThreeDotsIcon", {
  enumerable: true,
  get: function get() {
    return _three_dots_icon["default"];
  }
});
Object.defineProperty(exports, "ThumbsUpIcon", {
  enumerable: true,
  get: function get() {
    return _thumbs_up_icon["default"];
  }
});
Object.defineProperty(exports, "ThumbsUpOutlineIcon", {
  enumerable: true,
  get: function get() {
    return _thumbs_up_outline_icon["default"];
  }
});
Object.defineProperty(exports, "TilesIcon", {
  enumerable: true,
  get: function get() {
    return _tiles_icon["default"];
  }
});
Object.defineProperty(exports, "TimesWithCircleIcon", {
  enumerable: true,
  get: function get() {
    return _times_with_circle_icon["default"];
  }
});
Object.defineProperty(exports, "ToggleUserIcon", {
  enumerable: true,
  get: function get() {
    return _toggle_user_icon["default"];
  }
});
Object.defineProperty(exports, "TranslateIcon", {
  enumerable: true,
  get: function get() {
    return _translate_icon["default"];
  }
});
Object.defineProperty(exports, "TrashCanIcon", {
  enumerable: true,
  get: function get() {
    return _trash_can_icon["default"];
  }
});
Object.defineProperty(exports, "TreeIcon", {
  enumerable: true,
  get: function get() {
    return _tree_icon["default"];
  }
});
Object.defineProperty(exports, "TriangleProgress", {
  enumerable: true,
  get: function get() {
    return _triangle_progress["default"];
  }
});
Object.defineProperty(exports, "TriviaCircleIcon", {
  enumerable: true,
  get: function get() {
    return _trivia_circle_icon["default"];
  }
});
Object.defineProperty(exports, "TriviaIcon", {
  enumerable: true,
  get: function get() {
    return _trivia_icon["default"];
  }
});
Object.defineProperty(exports, "TriviamasterIcon", {
  enumerable: true,
  get: function get() {
    return _triviamaster_icon["default"];
  }
});
Object.defineProperty(exports, "UnknownUserIcon", {
  enumerable: true,
  get: function get() {
    return _unknown_user_icon["default"];
  }
});
Object.defineProperty(exports, "UserIcon", {
  enumerable: true,
  get: function get() {
    return _user_icon["default"];
  }
});
Object.defineProperty(exports, "UserSolidIcon", {
  enumerable: true,
  get: function get() {
    return _user_solid_icon["default"];
  }
});
Object.defineProperty(exports, "VideoIcon", {
  enumerable: true,
  get: function get() {
    return _video_icon["default"];
  }
});
Object.defineProperty(exports, "VocabThinIcon", {
  enumerable: true,
  get: function get() {
    return _vocab_thin_icon["default"];
  }
});
Object.defineProperty(exports, "WarningTriangleIcon", {
  enumerable: true,
  get: function get() {
    return _warning_triangle_icon["default"];
  }
});
Object.defineProperty(exports, "WordListIcon", {
  enumerable: true,
  get: function get() {
    return _word_list_icon["default"];
  }
});
Object.defineProperty(exports, "WreathIcon", {
  enumerable: true,
  get: function get() {
    return _wreath_icon["default"];
  }
});
Object.defineProperty(exports, "XpIcon", {
  enumerable: true,
  get: function get() {
    return _xp_icon["default"];
  }
});
Object.defineProperty(exports, "YoutubeIcon", {
  enumerable: true,
  get: function get() {
    return _youtube_icon["default"];
  }
});
var _add_fat_icon = _interopRequireDefault(require("./add_fat_icon"));
var _add_icon = _interopRequireDefault(require("./add_icon"));
var _alert_icon = _interopRequireDefault(require("./alert_icon"));
var _arrow_icon = _interopRequireDefault(require("./arrow_icon"));
var _badge_icon = _interopRequireDefault(require("./badge_icon"));
var _bell_icon = _interopRequireDefault(require("./bell_icon"));
var _bell_outline_icon = _interopRequireDefault(require("./bell_outline_icon"));
var _big_smile_face_icon = _interopRequireDefault(require("./big_smile_face_icon"));
var _book_icon = _interopRequireDefault(require("./book_icon"));
var _brain_icon = _interopRequireDefault(require("./brain_icon"));
var _broken_circle_icon = _interopRequireDefault(require("./broken_circle_icon"));
var _broken_icon = _interopRequireDefault(require("./broken_icon"));
var _cards_icon = _interopRequireDefault(require("./cards_icon"));
var _case_icon = _interopRequireDefault(require("./case_icon"));
var _chat_icon = _interopRequireDefault(require("./chat_icon"));
var _chat_icon_with_count = _interopRequireDefault(require("./chat_icon_with_count"));
var _chat_outline_icon = _interopRequireDefault(require("./chat_outline_icon"));
var _chat_outline_icon_ = _interopRequireDefault(require("./chat_outline_icon_2"));
var _check_icon = _interopRequireDefault(require("./check_icon"));
var _check_with_circle_icon = _interopRequireDefault(require("./check_with_circle_icon"));
var _checkmark_outline_icon = _interopRequireDefault(require("./checkmark_outline_icon"));
var _clock_face_icon = _interopRequireDefault(require("./clock_face_icon"));
var _clock_icon = _interopRequireDefault(require("./clock_icon"));
var _clockface_icon = _interopRequireDefault(require("./clockface_icon"));
var _close_icon = _interopRequireDefault(require("./close_icon"));
var _close_icon_red_x = _interopRequireDefault(require("./close_icon_red_x"));
var _close_with_circle_icon = _interopRequireDefault(require("./close_with_circle_icon"));
var _comparison_icon = _interopRequireDefault(require("./comparison_icon"));
var _crown_icon = _interopRequireDefault(require("./crown_icon"));
var _deck_icon = _interopRequireDefault(require("./deck_icon"));
var _deckmaster_icon = _interopRequireDefault(require("./deckmaster_icon"));
var _der_die_das_icon = _interopRequireDefault(require("./der_die_das_icon"));
var _double_arrow_icon = _interopRequireDefault(require("./double_arrow_icon"));
var _down_icon = _interopRequireDefault(require("./down_icon"));
var _edit_icon = _interopRequireDefault(require("./edit_icon"));
var _emblem_icon = _interopRequireDefault(require("./emblem_icon"));
var _erase_icon = _interopRequireDefault(require("./erase_icon"));
var _exclamation_icon = _interopRequireDefault(require("./exclamation_icon"));
var _expand_icon = _interopRequireDefault(require("./expand_icon"));
var _feed_icon = _interopRequireDefault(require("./feed_icon"));
var _filter_icon = _interopRequireDefault(require("./filter_icon"));
var _flag_ab_icon = _interopRequireDefault(require("./flag_ab_icon"));
var _flag_ar_icon = _interopRequireDefault(require("./flag_ar_icon"));
var _flag_bg_icon = _interopRequireDefault(require("./flag_bg_icon"));
var _flag_br_icon = _interopRequireDefault(require("./flag_br_icon"));
var _flag_bs_icon = _interopRequireDefault(require("./flag_bs_icon"));
var _flag_ca_icon = _interopRequireDefault(require("./flag_ca_icon"));
var _flag_cs_icon = _interopRequireDefault(require("./flag_cs_icon"));
var _flag_de_icon = _interopRequireDefault(require("./flag_de_icon"));
var _flag_en_icon = _interopRequireDefault(require("./flag_en_icon"));
var _flag_eo_icon = _interopRequireDefault(require("./flag_eo_icon"));
var _flag_es_icon = _interopRequireDefault(require("./flag_es_icon"));
var _flag_fa_icon = _interopRequireDefault(require("./flag_fa_icon"));
var _flag_fj_icon = _interopRequireDefault(require("./flag_fj_icon"));
var _flag_fo_icon = _interopRequireDefault(require("./flag_fo_icon"));
var _flag_fr_icon = _interopRequireDefault(require("./flag_fr_icon"));
var _flag_gd_icon = _interopRequireDefault(require("./flag_gd_icon"));
var _flag_gl_icon = _interopRequireDefault(require("./flag_gl_icon"));
var _flag_ha_icon = _interopRequireDefault(require("./flag_ha_icon"));
var _flag_hr_icon = _interopRequireDefault(require("./flag_hr_icon"));
var _flag_hu_icon = _interopRequireDefault(require("./flag_hu_icon"));
var _flag_in_icon = _interopRequireDefault(require("./flag_in_icon"));
var _flag_it_icon = _interopRequireDefault(require("./flag_it_icon"));
var _flag_ja_icon = _interopRequireDefault(require("./flag_ja_icon"));
var _flag_ko_icon = _interopRequireDefault(require("./flag_ko_icon"));
var _flag_kk_icon = _interopRequireDefault(require("./flag_kk_icon"));
var _flag_ku_icon = _interopRequireDefault(require("./flag_ku_icon"));
var _flag_la_icon = _interopRequireDefault(require("./flag_la_icon"));
var _flag_ml_icon = _interopRequireDefault(require("./flag_ml_icon"));
var _flag_mr_icon = _interopRequireDefault(require("./flag_mr_icon"));
var _flag_ms_icon = _interopRequireDefault(require("./flag_ms_icon"));
var _flag_nl_icon = _interopRequireDefault(require("./flag_nl_icon"));
var _flag_or_icon = _interopRequireDefault(require("./flag_or_icon"));
var _flag_pa_icon = _interopRequireDefault(require("./flag_pa_icon"));
var _flag_pl_icon = _interopRequireDefault(require("./flag_pl_icon"));
var _flag_ps_icon = _interopRequireDefault(require("./flag_ps_icon"));
var _flag_pt_icon = _interopRequireDefault(require("./flag_pt_icon"));
var _flag_ro_icon = _interopRequireDefault(require("./flag_ro_icon"));
var _flag_ru_icon = _interopRequireDefault(require("./flag_ru_icon"));
var _flag_rw_icon = _interopRequireDefault(require("./flag_rw_icon"));
var _flag_sh_icon = _interopRequireDefault(require("./flag_sh_icon"));
var _flag_si_icon = _interopRequireDefault(require("./flag_si_icon"));
var _flag_sq_icon = _interopRequireDefault(require("./flag_sq_icon"));
var _flag_sm_icon = _interopRequireDefault(require("./flag_sm_icon"));
var _flag_sr_icon = _interopRequireDefault(require("./flag_sr_icon"));
var _flag_sv_icon = _interopRequireDefault(require("./flag_sv_icon"));
var _flag_sw_icon = _interopRequireDefault(require("./flag_sw_icon"));
var _flag_ta_icon = _interopRequireDefault(require("./flag_ta_icon"));
var _flag_tg_icon = _interopRequireDefault(require("./flag_tg_icon"));
var _flag_te_icon = _interopRequireDefault(require("./flag_te_icon"));
var _flag_tk_icon = _interopRequireDefault(require("./flag_tk_icon"));
var _flag_tr_icon = _interopRequireDefault(require("./flag_tr_icon"));
var _flag_uk_icon = _interopRequireDefault(require("./flag_uk_icon"));
var _flag_ur_icon = _interopRequireDefault(require("./flag_ur_icon"));
var _flag_us_icon = _interopRequireDefault(require("./flag_us_icon"));
var _flag_vi_icon = _interopRequireDefault(require("./flag_vi_icon"));
var _flag_wo_icon = _interopRequireDefault(require("./flag_wo_icon"));
var _flag_yo_icon = _interopRequireDefault(require("./flag_yo_icon"));
var _flag_zh_icon = _interopRequireDefault(require("./flag_zh_icon"));
var _flame_icon = _interopRequireDefault(require("./flame_icon"));
var _gender_icon = _interopRequireDefault(require("./gender_icon"));
var _graph_icon = _interopRequireDefault(require("./graph_icon"));
var _graph_outline_icon = _interopRequireDefault(require("./graph_outline_icon"));
var _group_icon = _interopRequireDefault(require("./group_icon"));
var _heart_de_icon = _interopRequireDefault(require("./heart_de_icon"));
var _help_icon = _interopRequireDefault(require("./help_icon"));
var _home_icon = _interopRequireDefault(require("./home_icon"));
var _info_icon = _interopRequireDefault(require("./info_icon"));
var _key_icon = _interopRequireDefault(require("./key_icon"));
var _language_icon = _interopRequireDefault(require("./language_icon"));
var _learned_icon = _interopRequireDefault(require("./learned_icon"));
var _arrow_left_icon = _interopRequireDefault(require("./arrow_left_icon"));
var _lightbulb_icon = _interopRequireDefault(require("./lightbulb_icon"));
var _lightbulb_off_icon = _interopRequireDefault(require("./lightbulb_off_icon"));
var _lightbulb_on_icon = _interopRequireDefault(require("./lightbulb_on_icon"));
var _lightbulb_on_solid_icon = _interopRequireDefault(require("./lightbulb_on_solid_icon"));
var _lightning_bolt_icon = _interopRequireDefault(require("./lightning_bolt_icon"));
var _list_icon = _interopRequireDefault(require("./list_icon"));
var _lock_icon = _interopRequireDefault(require("./lock_icon"));
var _magnify_words_icon = _interopRequireDefault(require("./magnify_words_icon"));
var _membership_1leaf_icon = _interopRequireDefault(require("./membership_1leaf_icon"));
var _membership_2leaf_icon = _interopRequireDefault(require("./membership_2leaf_icon"));
var _membership_3leaf_icon = _interopRequireDefault(require("./membership_3leaf_icon"));
var _membership_tree1_icon = _interopRequireDefault(require("./membership_tree1_icon"));
var _membership_tree2_icon = _interopRequireDefault(require("./membership_tree2_icon"));
var _menu_icon = _interopRequireDefault(require("./menu_icon"));
var _microphone_badge_icon = _interopRequireDefault(require("./microphone_badge_icon"));
var _microphone_icon = _interopRequireDefault(require("./microphone_icon"));
var _minus_icon = _interopRequireDefault(require("./minus_icon"));
var _neutral_face_icon = _interopRequireDefault(require("./neutral_face_icon"));
var _no_microphone_icon = _interopRequireDefault(require("./no_microphone_icon"));
var _notifications_icon = _interopRequireDefault(require("./notifications_icon"));
var _past_due_icon = _interopRequireDefault(require("./past_due_icon"));
var _patreon_icon = _interopRequireDefault(require("./patreon_icon"));
var _pencil_icon = _interopRequireDefault(require("./pencil_icon"));
var _pencil_with_circle_icon = _interopRequireDefault(require("./pencil_with_circle_icon"));
var _pencil_icon_thick = _interopRequireDefault(require("./pencil_icon_thick"));
var _pin_icon = _interopRequireDefault(require("./pin_icon"));
var _play_icon = _interopRequireDefault(require("./play_icon"));
var _plural_icon = _interopRequireDefault(require("./plural_icon"));
var _plus_icon = _interopRequireDefault(require("./plus_icon"));
var _progress_bar_end_icon = _interopRequireDefault(require("./progress_bar_end_icon"));
var _question_icon = _interopRequireDefault(require("./question_icon"));
var _recording_icon = _interopRequireDefault(require("./recording_icon"));
var _reduce_icon = _interopRequireDefault(require("./reduce_icon"));
var _refresh_icon = _interopRequireDefault(require("./refresh_icon"));
var _refresh_thick_icon = _interopRequireDefault(require("./refresh_thick_icon"));
var _regular_verb_icon = _interopRequireDefault(require("./regular_verb_icon"));
var _reveal_subtitles_icon = _interopRequireDefault(require("./reveal_subtitles_icon"));
var _right_icon = _interopRequireDefault(require("./right_icon"));
var _rotate_icon = _interopRequireDefault(require("./rotate_icon"));
var _seedlang_icon = _interopRequireDefault(require("./seedlang_icon"));
var _seedlang_icon_with_circle = _interopRequireDefault(require("./seedlang_icon_with_circle"));
var _seedlang_outline_icon = _interopRequireDefault(require("./seedlang_outline_icon"));
var _seedlang_outline_bold_icon = _interopRequireDefault(require("./seedlang_outline_bold_icon"));
var _search_words_icon = _interopRequireDefault(require("./search_words_icon"));
var _send_icon = _interopRequireDefault(require("./send_icon"));
var _dot_icon_wrapper = _interopRequireDefault(require("./dot_icon_wrapper"));
var _settings_icon = _interopRequireDefault(require("./settings_icon"));
var _settings_outline_icon = _interopRequireDefault(require("./settings_outline_icon"));
var _shakespeare_icon = _interopRequireDefault(require("./shakespeare_icon"));
var _shortcut_icon = _interopRequireDefault(require("./shortcut_icon"));
var _smile_face_icon = _interopRequireDefault(require("./smile_face_icon"));
var _sparkle_icon = _interopRequireDefault(require("./sparkle_icon"));
var _star_icon = _interopRequireDefault(require("./star_icon"));
var _star_outline_icon = _interopRequireDefault(require("./star_outline_icon"));
var _star_outline_thin_icon = _interopRequireDefault(require("./star_outline_thin_icon"));
var _star_with_circle_icon = _interopRequireDefault(require("./star_with_circle_icon"));
var _stop_icon = _interopRequireDefault(require("./stop_icon"));
var _streak_icon = _interopRequireDefault(require("./streak_icon"));
var _swap_icon = _interopRequireDefault(require("./swap_icon"));
var _table_icon = _interopRequireDefault(require("./table_icon"));
var _target_icon = _interopRequireDefault(require("./target_icon"));
var _three_cards_icon = _interopRequireDefault(require("./three_cards_icon"));
var _three_dots_icon = _interopRequireDefault(require("./three_dots_icon"));
var _thumbs_up_icon = _interopRequireDefault(require("./thumbs_up_icon"));
var _thumbs_up_outline_icon = _interopRequireDefault(require("./thumbs_up_outline_icon"));
var _tiles_icon = _interopRequireDefault(require("./tiles_icon"));
var _toggle_user_icon = _interopRequireDefault(require("./toggle_user_icon"));
var _trash_can_icon = _interopRequireDefault(require("./trash_can_icon"));
var _translate_icon = _interopRequireDefault(require("./translate_icon"));
var _tree_icon = _interopRequireDefault(require("./tree_icon"));
var _triangle_progress = _interopRequireDefault(require("./triangle_progress"));
var _trivia_icon = _interopRequireDefault(require("./trivia_icon"));
var _unknown_user_icon = _interopRequireDefault(require("./unknown_user_icon"));
var _user_icon = _interopRequireDefault(require("./user_icon"));
var _user_solid_icon = _interopRequireDefault(require("./user_solid_icon"));
var _video_icon = _interopRequireDefault(require("./video_icon"));
var _wreath_icon = _interopRequireDefault(require("./wreath_icon"));
var _xp_icon = _interopRequireDefault(require("./xp_icon"));
var _youtube_icon = _interopRequireDefault(require("./youtube_icon"));
var _profile_icon = _interopRequireDefault(require("./profile_icon"));
var _warning_triangle_icon = _interopRequireDefault(require("./warning_triangle_icon"));
var _reply_icon = _interopRequireDefault(require("./reply_icon"));
var _smile_icon = _interopRequireDefault(require("./smile_icon"));
var _eye_icon = _interopRequireDefault(require("./eye_icon"));
var _list_with_bullet_icon = _interopRequireDefault(require("./list_with_bullet_icon"));
var _triviamaster_icon = _interopRequireDefault(require("./triviamaster_icon"));
var _sentencewriter_icon = _interopRequireDefault(require("./sentencewriter_icon"));
var _cube_icon = _interopRequireDefault(require("./cube_icon"));
var _plural_outline_icon = _interopRequireDefault(require("./plural_outline_icon"));
var _caret_right_circle_icon = _interopRequireDefault(require("./caret_right_circle_icon"));
var _star_with_circle2_icon = _interopRequireDefault(require("./star_with_circle2_icon"));
var _graph_circle_icon = _interopRequireDefault(require("./graph_circle_icon"));
var _times_with_circle_icon = _interopRequireDefault(require("./times_with_circle_icon"));
var _left_icon = _interopRequireDefault(require("./left_icon"));
var _seedlang_pro_with_crown = _interopRequireDefault(require("./seedlang_pro_with_crown"));
var _bookmark_icon = _interopRequireDefault(require("./bookmark_icon"));
var _bookmark_outline_icon = _interopRequireDefault(require("./bookmark_outline_icon"));
var _note_icon = _interopRequireDefault(require("./note_icon"));
var _faq_icon = _interopRequireDefault(require("./faq_icon"));
var _classroom_icon = _interopRequireDefault(require("./classroom_icon"));
var _community_badge_icon = _interopRequireDefault(require("./community_badge_icon"));
var _jigsaw_icon = _interopRequireDefault(require("./jigsaw_icon"));
var _keyboard_icon = _interopRequireDefault(require("./keyboard_icon"));
var _numbers_icon = _interopRequireDefault(require("./numbers_icon"));
var _numbers_outline_icon = _interopRequireDefault(require("./numbers_outline_icon"));
var _multiple_choice_icon = _interopRequireDefault(require("./multiple_choice_icon"));
var _speaking_icon = _interopRequireDefault(require("./speaking_icon"));
var _dictionary_icon = _interopRequireDefault(require("./dictionary_icon"));
var _trivia_circle_icon = _interopRequireDefault(require("./trivia_circle_icon"));
var _word_list_icon = _interopRequireDefault(require("./word_list_icon"));
var _calendar_icon = _interopRequireDefault(require("./calendar_icon"));
var _clipboard_icon = _interopRequireDefault(require("./clipboard_icon"));
var _japanese_character_icon = _interopRequireDefault(require("./japanese_character_icon"));
var _vocab_thin_icon = _interopRequireDefault(require("./vocab_thin_icon"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
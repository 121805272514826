import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import CommentIndex from 'components/comment/comment_index';
import { ExerciseUI, ForumUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { Link } from "react-router";

const Wrapper = styled.div`

`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

@observer
class WorksheetComments extends React.Component {
  @observable commentTimestamp = null;

  @autobind afterSubmitComment() {
    this.commentTimestamp = +new Date();
  }

  render () {
    return (
      <Wrapper>
        {
          ExerciseUI.worksheet.forumThread &&
            <CommentIndex
              hideUserProfile
              showCommentCreate={ExerciseUI.worksheet.userHasAccess}
              commentTimestamp={this.commentTimestamp}
              groupId={this.props.params.groupId}
              inModal={false}
              post={ExerciseUI.worksheet.forumThread}
              showCommentCount
              user={ForumUI.user}
              commentable={ExerciseUI.worksheet.forumThread}
              commentableType="ForumThread"
              forumThreadId={ExerciseUI.worksheet.forumThread.id}
              margin="0"
              languageId={this.props.languageId}
              borderRadius={ExerciseUI.layout.isDesktop ? "0 10px 10px 10px" : '0'}
              padding="40px 20px 20px 20px"
              placeholder={`Leave a comment about the episode`}
              indentation={ExerciseUI.layout.isMobile ? 20 : 90}
            />
        }
        {
          ExerciseUI.user.anyAdmin && ExerciseUI.worksheet && ExerciseUI.worksheet.groupId &&
            <AdminLink>
              <Link
                to={{name: 'creator.groups.worksheets.edit', params: {groupId: ExerciseUI.worksheet.groupId, worksheetId: ExerciseUI.worksheet.id}}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetComments);

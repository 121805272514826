import React from 'react';
import { observer } from 'mobx-react';
import CommentShow from 'components/comment/comment_show';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Text from 'components/text';
import InPlaceSelect from 'components/form/in_place_select';
import { CommentIndexWrapper } from '@seedlang/hoc';
import CommentCreate from 'components/comment/comment_create';
import ForumThreadPost from 'components/forum_thread/forum_thread_post';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  font-size: 12px;
  width: 100%;
  background: white;
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  select {
    height: 30px;
  }
`;

const CommentsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: ${props => props.borderTop};
`;

const SelectWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

@observer
class CommentIndex extends React.Component {
  static defaultProps = {
    margin: "0 0 20px 0",
    padding: "20px",
    borderRadius: "5px",
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        padding={this.props.padding}
        borderRadius={this.props.borderRadius}
      >
        {
          this.props.showForumThreadPost &&
            <ForumThreadPost
              hideUserProfile={this.props.hideUserProfile}
              groupId={this.props.groupId}
              inModal={this.props.inModal}
              post={this.props.post}
              user={AppUI.user}
              onChange={this.props.onChange}
              onUpdateForumThread={this.props.onUpdateForumThread}
              hideForumThreadDetails={this.props.hideForumThreadDetails}
            />
        }
        {
          this.props.showCommentCreate &&
            <CommentCreate
              debugInfo={this.props.debugInfo}
              showXp={this.props.showXp}
              blockTextExpansion={this.props.blockTextExpansion}
              wordId={this.props.wordId}
              sentenceId={this.props.sentenceId}
              conceptId={this.props.conceptId}
              namespace={this.props.namespace || this.props.translationId || this.props.conceptId}
              commentable={this.props.commentable}
              commentableType={this.props.commentableType}
              commentableId={this.props.commentableId}
              afterCreateComment={this.props.onChange}
              languageId={this.props.languageId || AppUI.targetLanguageId}
              placeholder={this.props.placeholder}
              margin='0 0 10px 0'
            />
        }
        <span id='page-top' />
        {
          this.props.showSpinner &&
            <Spinner />
        }
        {
          this.props.comments.length > 1 &&
            <CommentsRow
              borderTop={this.props.showCommentCreate ? '1px solid #dedede' : ''}
            >
              {
                this.props.showCommentCount &&
                  <Text
                    bold
                  >
                    {AppUI.user.commentOrdering === 'created_at' ? 'Oldest' : 'Newest'} Comments
                  </Text>
              }
              <SelectWrapper>
                <InPlaceSelect
                  allowUpdate
                  value={AppUI.user.commentOrdering}
                  onChange={this.props.setCommentOrdering}
                  options={[
                    ['created_at', 'Sort by Old'],
                    ['-created_at', 'Sort by New'],
                  ]}
                />
              </SelectWrapper>
            </CommentsRow>
        }
        {
          this.props.comments.map((item, index) => {
            if (!item.hidden || item.user.id === AppUI.user.id || item.respondToId === AppUI.user.id || AppUI.user.admin || AppUI.user.moderator) {
              return (
                <CommentShow
                  hideUserProfile={this.props.hideUserProfile}
                  indentation={this.props.indentation}
                  key={item.id}
                  onChange={this.props.onChange}
                  commentable={this.props.commentable}
                  commentableType={this.props.commentableType}
                  commentableId={this.props.commentableId}
                  comment={item}
                  user={this.props.user}
                  fontSize={this.props.fontSize}
                  userProfileImageSize={this.props.userProfileImageSize}
                  margin={this.props.commentMargin}
                  padding={this.props.padding}
                  languageId={this.props.languageId || AppUI.targetLanguageId}
                />
              )
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default CommentIndexWrapper(CommentIndex);

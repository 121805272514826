import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { ConceptStore, TagStore } from '@seedlang/stores';
import InfoTooltip from "components/info_tooltip";
import BackEndExplainer from "pages/creator/back_end_explainer";
import WordTagCreate from "pages/creator/word_tags/word_tag_create";
import Paginator from "components/paginator";
import isBlank from "is-blank";
import { isPresent } from "@seedlang/utils/src";
import WordTagEdit from "pages/creator/word_tags/word_tag_edit";
import { observable } from "mobx";

const Wrapper = styled.div`
  .fa-edit {
    cursor: pointer;
  }
`;


@observer
class WordTagsIndex extends React.Component {
  @observable editConcept;

  constructor(props) {
    super(props);
    this.loadWordTags();
  }

  @autobind loadWordTags() {
    ConceptStore.getIndex({filters: {group_id: this.props.params.groupId}});
  }

  @autobind onDeleteTag(id) {
    TagStore.destroy({ids: {tagId: id}}, this.loadTags);
  }

  @autobind descriptionPreview(concept) {
    if (isBlank(concept.longDescription)) { return }
    const maxLength = 70;
    const parser = new DOMParser();
    const doc = parser.parseFromString(concept.longDescription, 'text/html');
    const textContent = doc.body.textContent;
    return `${textContent.slice(0,maxLength)}${textContent.length > maxLength ? '...' : ''}`
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.editConcept) &&
          <WordTagEdit
            concept={this.editConcept}
            onClose={() => this.editConcept = null}
            onChange={this.loadWordTags}
          />
        }
        <BackEndExplainer>These tags can be attached to words in posts's vocab section to help users understand the vocabulary.</BackEndExplainer>
        <WordTagCreate
          groupId={this.props.params.groupId}
          afterChange={this.loadWordTags}
        />
        <table className='table-wrapper' style={{marginTop: '10px'}}>
          <thead>
            <tr>
              <th width='250px'>Name</th>
              <th width='150px'>Abbreviation <InfoTooltip>This abbreviation will be displayed in the vocab list</InfoTooltip></th>
              <th>Description</th>
              <th width='50px'/>
            </tr>
          </thead>
          <tbody>
          {
            ConceptStore.indexData.map(concept => {
              return (
                <tr key={concept.id}>
                  <td>{concept.name}</td>
                  <td>{concept.abbreviation}</td>
                  <td>{this.descriptionPreview(concept)}</td>
                  <td onClick={() => this.editConcept = concept}><i className='fa fa-edit' /></td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
        <Paginator
          store={ConceptStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordTagsIndex);

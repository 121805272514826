import React from 'react';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { MembershipGroupStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import MembershipGroupCreate from 'pages/builder/membership_groups/membership_group_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';

@observer
class MembershipIndex extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  componentDidMount() {
    this.loadMembershipGroups();
  }

  @autobind onDelete(id) {
    MembershipGroupStore.destroy({ids: {membershipGroupId: id}}, this.loadMembershipGroups);
  }

  @autobind loadMembershipGroups() {
    MembershipGroupStore.clearFilter();
    MembershipGroupStore.getIndex();
  }

  @autobind loadUser() {
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
  }

  @computed get siteId() {
    return Cookies.get('site_id');
  }

  @computed get membershipGroups() {
    return MembershipGroupStore.indexData.filter(item => item.siteId === this.siteId);
  }

  render() {
    return (
      <div className='builder-membership-index'>
        <div className='row'>
          <div className='col-xs-12' style={{marginBottom: '20px'}}>
            <fieldset>
              <legend>Default Membership Group</legend>
              <div style={{width: "200px"}}>
                <InPlaceSelect
                  model="sites"
                  field="membership_group_id"
                  id={AppUI.site.id}
                  options={this.membershipGroups.map(item => [item.id, item.name])}
                  value={AppUI.site.membershipGroupId}
                  afterChange={this.loadUser}
                />
              </div>
              <small>If a user has not had a membership group assigned to them, this is the membership group that will be shown.</small>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <MembershipGroupCreate />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <table className="table-wrapper admin-table">
              <thead>
                <tr>
                  <th width="20" />
                  <th>Name</th>
                  <th>Slug</th>
                  <th># Memberships</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.membershipGroups.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={this.props.whiteLabeled ? { name: 'creator.groups.membership_groups.edit', params: { groupId: this.props.params.groupId, membershipGroupId: item.id } } : { name: 'builder.membership_groups.edit', params: { membershipGroupId: item.id } }}
                            className="on-click"
                          >
                            <i className="fa fa-chevron-right fa-block" />
                          </Link>
                        </td>
                        <td>
                          <div className="text">
                            <InPlaceText
                              id={item.id}
                              model="membership_group"
                              field="name"
                              defaultValue={item.name}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="text">
                            <InPlaceText
                              id={item.id}
                              model="membership_group"
                              field="slug"
                              defaultValue={item.slug}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="text">
                            {item.memberships.length}
                          </div>
                        </td>
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message="Delete this Payment Plan?"
                            className="fa-block right"
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <Paginator
              store={MembershipGroupStore}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MembershipIndex;

import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import LevelTag from "components/vocab/level_tag";
import Text from 'components/text';
import ReactTooltip from 'react-tooltip';
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  margin: 10px 0;
  flex-wrap: wrap;
`;

const LinkedWord = styled.span`
  border-bottom: ${props => props.noBorder ? '' : '2px solid #b5b5b5'};
  cursor: pointer;
`;

@observer
class RelatedWords extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.title &&
          <Text bold margin='0 3px 0 0'>{this.props.title}: </Text>
        }
        {
          this.props.words.map((item, index) => {
            return (
              <div
                onClick={() => this.props.onClickWord(item)}
                key={item.id}
                style={{display: 'flex', gap: '2px'}}
                data-tip
                data-for={item.id}
              >
                <LinkedWord>
                  {item.targetTextWithDefiniteArticle}
                </LinkedWord>
                <ReactTooltip
                  place='top'
                  type="dark"
                  effect="solid"
                  id={item.id}
                  class="custom-tooltip"
                >
                  <div style={{maxWidth: '200px'}}>{item.sourceList}</div>
                </ReactTooltip>
                {
                  item.level?.position > this.props.referenceWord.level?.position &&
                  <LevelTag level={item.level}/>
                }
                <Text margin='0 4px 0 0'>{index + 1 < this.props.words.length  ? ', ' : ''}</Text>
              </div>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default RelatedWords;

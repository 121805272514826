import React from 'react';
import { observer } from 'mobx-react';
import { SourceListWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Text from 'components/text';
import { LanguageIcon } from '@seedlang/icons';
import { pixify } from '@seedlang/utils';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import { isFunction } from "lodash";
import InfoTooltip from "components/info_tooltip";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .source-wrapper:last-child {
    border-bottom: none;
  }
  cursor: ${(props) => props.cursor};
`;

const SourceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.leftAlign ? 'left' : 'center'};
  flex: 1;
  width: 100%;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background: ${(props) => props.background};
  border-bottom: ${(props) => props.borderBottom};
  .fa-times {
    color: #b3b3b3;
  }
`;

const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.flagWrapperAlignLeft ? 'flex-start' : 'center' };
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  .icon-wrapper {
    height: 20px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const MoreSourcesButton = styled.div`
  margin: 5px; 
  cursor: pointer;
  font-style: italic;
  color: #676767;
  font-size: 14px;
`;

@observer
class SourceList extends React.Component {

  static defaultProps = {
    flagWrapperWidth: 25,
    hideGender: false,
    fontSize: 30,
    numberPreviewSources: 1,
  }

  render() {
    return (
      <Wrapper
        onClick={ this.props.translations?.length > this.props.numberPreviewSources && this.props.showMoreTranslationsButton ? this.props.expandSources : this.props.onClick }
        cursor={ this.props.translations?.length > this.props.numberPreviewSources || isFunction(this.props.onClick) ? 'pointer' : null }
      >
        {
          this.props.showSpinner &&
            <Spinner />
        }
        {
          this.props.word && this.props.translations && this.props.translations.map((item, index) => {
            if (this.props.showMoreTranslationsButton && index > 0) {
              return null;
            }
            return (
              <SourceWrapper
                key={item.id}
                borderBottom={this.props.borderBottom}
                leftAlign={this.props.leftAlign}
                translate={this.props.translate}
                className='source-wrapper'
                padding={this.props.sourcePadding}
                margin={this.props.sourceMargin}
                background={this.props.background}
              >
                {
                  this.props.showFlag &&
                    <FlagWrapper
                      width={pixify(this.props.flagWrapperWidth)}
                      flagWrapperAlignLeft={this.props.flagWrapperAlignLeft}
                    >
                      <LanguageIcon
                        margin="0 5px 0 0"
                        languageId={item.source && item.source?.languageId}
                        useUkFlagForEn={this.props.user?.useBritishEnglish}
                      />
                    </FlagWrapper>
                }
                {
                  item.source &&
                    <TextWrapper>
                      <Text
                        fontSize={pixify(this.props.translationFontSize(item))}
                        lineHeight={pixify(this.props.translationLineHeight(item))}
                        textAlign={this.props.leftAlign ? 'left' : ''}
                      >
                        {item.source.textForUser(this.props.user)}
                        {item.source.displayGender && !this.props.hideGender && this.props.word.genderAbbreviation}
                      </Text>
                    </TextWrapper>
                }
                {
                  AppUI.user.admin && item.translatedByAi &&
                  <InfoTooltip color='#b3b3b3' icon='magic'><div>Translated by AI</div><div>[admins only]</div></InfoTooltip>
                }
                {
                  this.props.onRemoveSource && (this.props.user.id === item.userId || (AppUI.user.adminOrTranslator && ((item.source && ['EN','EN2'].indexOf(item.source?.languageId) === -1) || (this.props.user.languageId === 'EN2' && item.source?.languageId === 'EN2')))) &&
                    <i
                      className='fa fa-times fa-on-click'
                      style={{marginLeft: 5}}
                      onClick={() => this.props.onRemoveSource(item)}
                    />
                }
                {
                  this.props.showFlag &&
                    <FlagWrapper
                      width={pixify(this.props.flagWrapperWidth)}
                      flagWrapperAlignLeft={this.props.flagWrapperAlignLeft}
                    >
                    </FlagWrapper>
                }
              </SourceWrapper>
            )
          })
        }
        {
          this.props.showMoreTranslationsButton &&
            <MoreSourcesButton>
              See {this.props.translations?.length - this.props.numberPreviewSources} More Translations
            </MoreSourcesButton>
        }
      </Wrapper>
    );
  }
}

export default SourceListWrapper(SourceList);

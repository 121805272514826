import React from 'react';
import SubscriptionIndex from 'pages/builder/subscriptions/subscription_index';
import { AppUI } from '@seedlang/state';
import { autorun } from 'mobx';

class SiteSubscriptionIndex extends React.Component {

  constructor(props) {
    super(props);
    autorun(() => {
      if (AppUI.userIsLoaded && !AppUI.permissions.canAccessBasicUserData) {
        AppUI.routeStore.routeToNamed('creator.groups.worksheets.index', {groupId: this.props.params.groupId});
      }
    })
  }

  render() {
    return (
      <SubscriptionIndex
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteSubscriptionIndex;

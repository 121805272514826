import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI, PrivacySettingsState, PrivacySettingsUI } from '@seedlang/state';
import Modal from 'components/modal';
import PrivacySettingsModal from 'components/cookie_agreement_modal';

const Wrapper = styled.div`
  background: ${props => props.background};
  line-height: 20px;
  width: 100%;
  height: ${props => props.height};
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  a {
    color: white;
  }
`;

const Button = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ButtonWrapper = styled.div`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  flex-direction: ${props => props.flexDirection}
`;

const Container = styled.div`
  max-width: 1180px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  text-align: center;
  flex-direction: ${props => props.flexDirection};
  padding: 0 10px;
`;

const Text = styled.div`
  flex: 1;
  text-align: left;
  padding: ${props => props.padding};
`;


@observer
class CookieAgreement extends React.Component {
  render() {
    return (
      <>
        {
          PrivacySettingsUI.isModalVisible &&
            <Modal
              padding="0px"
              top="140px"
              width="500px"
              height={AppUI.layout.viewportHeight - 300}
              onCloseModal={PrivacySettingsUI.hideModal}
            >
              <PrivacySettingsModal
                onCloseModal={ PrivacySettingsUI.hideModal}
              />
            </Modal>
        }
        {
          PrivacySettingsUI.isBannerVisible && (
            <Wrapper
              height={AppUI.layout.isMobile ? '200px' : '90px'}
              background={AppUI.site.secondaryAccentColor || Theme.darkestBlue}
            >
              <Container
                flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
              >
                <Text
                  padding={AppUI.layout.isMobile ? '0 0 10px 0' : '0 10px 0 0'}
                >
                  This website uses cookies to improve your user experience. View our <a href='/privacy' className='underline' style={{display: 'inline'}}>Privacy Policy</a>.
                </Text>
                <ButtonWrapper
                  flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
                  width={AppUI.layout.isMobile ? '100%' : '440px'}
                >
                  <Button
                    background="#FFF"
                    color={AppUI.site.accentColor || "#0584a1"}
                    onClick={PrivacySettingsUI.acceptAll}
                    width={AppUI.layout.isMobile ? '100%' : '160px'}
                    margin={AppUI.layout.isMobile ? '5px 0 0 0' : '0 0 0 5px'}
                  >
                    Accept All
                  </Button>
                  <Button
                    background="#FFF"
                    color={AppUI.site.accentColor || "#0584a1"}
                    onClick={PrivacySettingsUI.rejectAll}
                    width={AppUI.layout.isMobile ? '100%' : '160px'}
                    margin={AppUI.layout.isMobile ? '5px 0 0 0' : '0 0 0 5px'}
                  >
                    Reject Optional
                  </Button>
                  <Button
                    background={AppUI.site.accentColor || "#0584a1"}
                    color="#FFF"
                    onClick={PrivacySettingsUI.showModal}
                    width={AppUI.layout.isMobile ? '100%' : '160px'}
                    margin={AppUI.layout.isMobile ? '5px 0 0 0' : '0 0 0 5px'}
                  >
                    Customize
                  </Button>
                </ButtonWrapper>
              </Container>
            </Wrapper>

          )
        }
      </>
    )
  }
}

export default CookieAgreement;

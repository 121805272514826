import React from 'react';
import { observer } from 'mobx-react';
import { ConjugationTableWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import cx from 'classnames';
import Text from 'components/text';
import Button from 'components/button/button';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { isPresent, displayTargetTextCorrectedForLanguage } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  width: 100%;
`;

const TableWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const Table = styled.div`
  width: 100%;
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.background};
  border-bottom: 1px solid #CCC;
`;

const Cell = styled.div`
  flex: 1;
  padding: ${(props) => props.padding || '10px'};
  color: ${(props) => props.color};
  display: flex;
  font-weight: ${props => props.fontWeight};
  text-align: left;
`;

const Header = styled.div`
  ${flexCenterColumn()}
`;

const HiddenFieldMessage = styled.div`
  display: flex;
  margin: 10px 0;
  i {
    color: #CCC!important;
  }
`;

@observer
class ConjugationTable extends React.Component {

  static defaultProps = {
    padding: "20px",
    fontSize: "14px",
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
        fontSize={this.props.fontSize}
      >
        {
          !this.props.hideHeader && this.props.word.root && isPresent(this.props.word.conjugatedVerbStringsSummary) &&
            <Header>
              <Text
                heading="2"
              >
                {this.props.word.targetText}
              </Text>
              <Text
                italic
                margin="0 0 20px 0"
              >
                {this.props.word.conjugatedVerbStringsSummary}
              </Text>
            </Header>
        }
        {
          this.props.conjugatedVerbStrings.map(item => {
            if ([item.singular1p, item.singular2p, item.singular3p, item.plural1p, item.plural2p, item.plural3p].some(x => isPresent(x))
              && (this.props.expand || this.props.expanded || (!this.props.conceptId && this.props.word && this.props.word.isInfinitive) || (this.props.conceptId && item.concept?.id === this.props.conceptId) || (!this.props.conceptId && item.concept && this.props.word && this.props.word.hasConcept && this.props.word.hasConcept(item.concept.id))) ) {
              return (
                <TableWrapper
                  key={item.id}
                >
                  <Table>
                    {
                      item.concept &&
                        <Row
                          background="#777"
                        >
                          <Cell
                            color="#FFF"
                            padding="5px 10px"
                            fontWeight="bold"
                          >
                            {item.concept && item.concept.name}
                          </Cell>
                          <Cell
                            color="#FFF"
                            padding="5px 10px"
                          >
                          </Cell>
                        </Row>
                    }
                    {
                       !item.hideSingular1p &&
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'singular_1p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {item.singular1p ? this.props.singular1pPronoun(item.singular1p) : Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_1p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.singular1pWithDash, this.props.word.languageId) || "" }}
                          />
                      </Row>
                    }
                    {
                      !item.hideSingular2p &&
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'singular_2p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_2p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.singular2pWithDash, this.props.word.languageId) || "" }}
                          />
                        </Row>
                    }
                    {
                      !item.hideSingular3p &&
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'singular_3p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_3p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.singular3pWithDash, this.props.word.languageId) || "" }}
                          />
                        </Row>
                    }
                    {
                      !item.hidePlural1p && 
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'plural_1p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_1p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.plural1pWithDash, this.props.word.languageId) || "" }}
                          />
                        </Row>
                    }
                    {
                      !item.hidePlural2p && 
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'plural_2p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_2p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.plural2pWithDash, this.props.word.languageId) || "" }}
                          />
                        </Row>
                    }
                    {
                      !item.hidePlural3p &&
                        <Row
                          background={this.props.conceptId && item.concept?.id === this.props.conceptId && this.props.highlightPronoun === 'plural_3p' ? '#ffffd7' : 'transparent'}
                        >
                          <Cell>
                            {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_3p}
                          </Cell>
                          <Cell
                            dangerouslySetInnerHTML={{ __html: displayTargetTextCorrectedForLanguage(item.plural3pWithDash, this.props.word.languageId) || ""  }}
                          />
                        </Row>
                    }
                  </Table>
                </TableWrapper>
              )
            } else {
              return <span key={item.id} />
            }
          })
        }
        {
          this.props.expanded && this.props.word.conjugationTableHasHiddenField && 
            <HiddenFieldMessage>
              <i className='fa fa-warning' /> Conjugations were removed because the conjugated form is never used.
            </HiddenFieldMessage>
        }
        {
          !this.props.expand && this.props.word && (!this.props.isInfinitive || this.props.conceptId) && this.props.grammarTenseInConjugatedStrings &&
            <Button
              onClick={this.props.onToggleExpanded}
              margin="0 0 20px 0"
            >
              <i
                className={cx('fa', {
                  'fa-plus': !this.props.expanded,
                  'fa-minus': this.props.expanded,
                })}
                style={{
                  margin: "3px 5px 0 0",
                }}
              />
              {this.props.expanded ? 'Show Current Tense' : 'Show All Tenses' }
            </Button>
        }
      </Wrapper>
    );
  }
}

export default ConjugationTableWrapper(ConjugationTable);

import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import FilterComponent from 'components/backend_filter/filter_component';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  .fa-search {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #8e8e8e;
  }
  input {
    padding-left: 25px;
  }
`;

@observer
class FilterText extends React.Component {
  @observable value = this.props.default;
  @observable filterValue;

  @computed get valueIsUuid() {
    return this.value.length === 36 && isPresent(this.value.match('-'));
  }

  componentDidMount() {
    if (this.props.default && this.props.default !== this.value) {
      this.setFilter(this.props.default);
    }
    let value = this.props.store.getFilterValue(this.props.name);
    if (isPresent(value)) {
      value = value.toString().replace(/~/g, '');
    }
    this.value = value;
    this.filterValue = value;
  }

  @autobind onChange() {
    this.value = this.refs.input.value;
    if (isBlank(this.value)) {
      this.setFilter();
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.setFilter, 500)
    }
  }

  @autobind setFilter() {
    let value;
    if (isBlank(this.value)) {
      value = '';
    } else if (this.value.match('"') || this.props.hidden || this.props.exactValue || this.valueIsUuid) {
      value = this.value.trim();
    } else if (this.props.searchFromBeginning) {
      value = `${this.value.trim()}~`;
    } else {
      value = `~${this.value.trim()}~`;
    }
    this.props.store.setFilter(this.props.name, value, { refresh: true });
  }

  render() {
    return (
      <FilterComponent
        selected={isPresent(this.value)}
        hidden={this.props.hidden}
      >
        <Wrapper>
          { !this.props.hidden && <i className="fa fa-search" />}
          <input
            type={this.props.hidden ? 'hidden' : 'text'}
            onChange={this.onChange}
            placeholder={this.props.placeholder || `Enter ${this.props.label || this.props.name}`}
            ref="input"
            value={this.value}
          />
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterText;

import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseMatchMediaWrapper } from '@seedlang/hoc';
import VideoPlayer from 'components/media/video_player';
import { DeckUI, ExerciseUI } from '@seedlang/state';
import LanguageButton from 'components/button/language_button';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import { isPresent, pixify } from '@seedlang/utils';
import ExerciseEntry from 'components/exercise/exercise_entry';
import TextDisplay from 'components/text_display';
import Text from "components/text";
import { computed } from "mobx";

const Wrapper = styled.div`
  height: ${(props) => props.height};
`;

const VideoSection = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const VideoWrapper = styled.div`
  ${flexCenterColumn()}
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
`;

const MediaOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${(props) => props.justifyContent};
  margin-bottom: ${(props) => props.isMobile || !props.inDeck ? '20px' : 0};
  gap: 10px;
`;

const MediaOption = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.border};
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

const AnswerWrapper = styled.div`
  margin-bottom: 20px;
`;

const Instructions = styled.div`
  font-size: 14px;
  font-weight: bold;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Answer = styled.div`
  ${flexCenter()}
  cursor: pointer;
  height: 44px;
`;

@observer
class ExerciseMatchMedia extends React.Component {

  @computed get justifyContentMedia() {
    if (this.props.inDeck) {
      return 'center';
    }
    return ExerciseUI.layout.viewportWidth > 1190 ? 'flex-start' : 'space-between';
  }

  render() {
    return (
      <Wrapper
        height={this.props.inDeck ? "100%" : "fit-content"}
      >
        {
          this.props.exercise && this.props.exercise.exerciseEntries.map(exerciseEntry => {
            return (
              <ExerciseEntry
                inDeck={this.props.inDeck}
                key={exerciseEntry.id}
                grammarTip={exerciseEntry.grammarTip}
                showButtons={!this.props.inDeck && this.props.exercise.exerciseEntries.length > 1}
                exerciseEntry={exerciseEntry}
                flexDirection={ExerciseUI.layout.viewportWidth > 1190 ? 'row' : 'column'}
                {...this.props}
              >
                {
                  ExerciseUI.worksheet && exerciseEntry.prompt &&
                    <Prompt>
                      <TextDisplay
                        markdown={exerciseEntry.prompt}
                      />
                    </Prompt>
                }
                {
                  isPresent(exerciseEntry.word) &&
                    <VideoSection>
                      <VideoWrapper
                        height={pixify(Math.floor(DeckUI.layout.deckWidth / 3.4)+10)}
                        width="180px"
                      >
                        <VideoPlayer
                          maximized={false}
                          autoPlay={this.props.inView}
                          circle
                          videoClip={ exerciseEntry.word.videoClip }
                          width={Math.floor(DeckUI.layout.deckWidth / 3.4)}
                        />
                      </VideoWrapper>
                    </VideoSection>
                }
                {
                  !ExerciseUI.hasWorksheet &&
                    <AnswerWrapper>
                      {
                        !this.props.exercise.state?.revealed &&
                          <Instructions>{isPresent(exerciseEntry.instruction) ? exerciseEntry.instruction : "Click on the image that matches best."}</Instructions>
                      }
                      {
                        this.props.exercise.state?.revealed &&
                          <Answer
                            onClick={() => this.props.setLanguageId(this.props.languageId !== 'EN' ? 'EN' : DeckUI.deck.languageId)}
                          >
                            {
                              isPresent(exerciseEntry.word) &&
                                <LanguageButton
                                  margin="5px 5px 0 0"
                                  languageId={this.props.languageId}
                                />
                            }
                            {
                              exerciseEntry.word &&
                                <Prompt>
                                  {this.props.languageId === DeckUI.deck.languageId ? exerciseEntry.word.targetTextWithDefiniteArticle : exerciseEntry.word.sourceText}
                                </Prompt>
                            }
                          </Answer>
                      }
                    </AnswerWrapper>
                }
                <MediaOptions
                  inDeck={this.props.inDeck}
                  isMobile={ExerciseUI.layout.isMobile}
                  justifyContent={this.justifyContentMedia}
                >
                  {
                    exerciseEntry.shuffledMediaOptions.map(mediaOption => {
                      return (
                        <MediaOption
                          width={pixify(this.props.mediaOptionWidthForExerciseEntry(exerciseEntry))}
                          inDeck={this.props.inDeck}
                          isMobile={ExerciseUI.layout.isMobile}
                          key={mediaOption.id}
                          border={this.props.border(mediaOption, exerciseEntry)}
                          onClick={() => this.props.onClick(exerciseEntry, mediaOption)}
                        >
                          {
                            isPresent(mediaOption.image) &&
                              <img
                                src={mediaOption.image.url}
                                alt=""
                              />
                          }
                        </MediaOption>
                      )
                    })
                  }
                </MediaOptions>
                {
                  exerciseEntry.numberOfCorrectOptions > 1 && exerciseEntry.selectAllCorrectOptions &&
                    <Text italic fontSize="13px" color="#333" margin="10px 0 0 0">
                      Select all correct answers.
                    </Text>
                }
              </ExerciseEntry>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseMatchMediaWrapper(ExerciseMatchMedia);

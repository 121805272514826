import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import styled from '@emotion/styled';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { SubscriptionStore, SubscriptionPaymentStore } from '@seedlang/stores';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import autobind from 'autobind-decorator';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import Button from 'components/button/button';
import PaymentIndex from 'pages/builder/payments/payment_index';
import Alert from 'components/alert';
import DeleteButton from 'components/button/delete_button';
import { AppUI } from '@seedlang/state';
import UserSubscriptionCreate from 'pages/builder/user_subscriptions/user_subscription_create';

const Cancellation = styled.div`
  background: #FFF;
  border: 1px solid #CCC;
  padding: 10px;
  margin: 5px 0;
`;

const CancellationReason = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Deleted = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin-bottom: 10px;
`;

@observer
class SubscriptionEdit extends React.Component {
  @observable showPaymentsUpdatedMessage = false;

  @computed get showPaymentsButton() {
    return SubscriptionStore.hasShowData && ['paypal', 'stripe'].indexOf(SubscriptionStore.showData.paymentMethod) !== -1;
  }

  componentDidMount() {
    this.loadSubscription();
  }

  @autobind loadSubscription() {
    SubscriptionStore.getShow({ids: {subscriptionId: this.props.params.subscriptionId}});
    this.loadPayments();
  }

  @autobind loadPayments() {
    SubscriptionPaymentStore.getIndex({ids: {subscriptionId: this.props.params.subscriptionId}});
  }

  @autobind updatePayments() {
    SubscriptionStore.updatePayments({ids: {subscriptionId: this.props.params.subscriptionId}}, this.afterUpdatePayments);
  }

  @autobind afterUpdatePayments() {
    this.showPaymentsUpdatedMessage = true;
    this.loadPayments();
  }

  @autobind onDeleteUser(userId) {
    AppUI.userSubscriptionStore.destroyByAttributes({data: {user_id: userId, subscriptionId: SubscriptionStore.showData.id}}, this.loadSubscription);
  }

  render() {
    return (
      <div className='subscription-show'>
        {
          SubscriptionStore.hasShowData &&
            <div>
              <div className="row">
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Membership</legend>
                    {SubscriptionStore.showData.membership ? SubscriptionStore.showData.membership.title : '[DELETED]'}
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Payment Method</legend>
                    <InPlaceSelect
                      includeBlank
                      id={SubscriptionStore.showData.id}
                      field="payment_method"
                      model="subscriptions"
                      value={SubscriptionStore.showData.paymentMethod}
                      options={['android', 'ios', 'paypal', 'stripe']}
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Current Start</legend>
                    <InPlaceDatePicker
                      id={SubscriptionStore.showData.id}
                      value={SubscriptionStore.showData.currentPeriodStart}
                      model="subscriptions"
                      field="current_period_start"
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Current End</legend>
                    <InPlaceDatePicker
                      id={SubscriptionStore.showData.id}
                      value={SubscriptionStore.showData.currentPeriodEnd}
                      model="subscriptions"
                      field="current_period_end"
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Amount</legend>
                    {SubscriptionStore.showData.amountFormatted}
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Amount USD</legend>
                    {SubscriptionStore.showData.amountUsdFormatted}
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Settings</legend>
                    <div>
                      <InPlaceCheckbox
                        model="subscriptions"
                        field="cancel_at_period_end"
                        id={SubscriptionStore.showData.id}
                        value={SubscriptionStore.showData.cancelAtPeriodEnd}
                        icon="check"
                      >
                        Cancelled
                      </InPlaceCheckbox>
                      {
                        SubscriptionStore.showData.cancellationReason &&
                          <Cancellation>
                            <CancellationReason>
                              {SubscriptionStore.showData.cancellationReason}
                            </CancellationReason>
                            {
                              SubscriptionStore.showData.cancellationMessage &&
                                <p>
                                  {SubscriptionStore.showData.cancellationMessage}
                                </p>
                            }
                          </Cancellation>
                      }
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model="subscriptions"
                        field="refunded"
                        id={SubscriptionStore.showData.id}
                        value={SubscriptionStore.showData.refunded}
                        icon="check"
                      >
                        Refunded
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model="subscriptions"
                        field="pending"
                        id={SubscriptionStore.showData.id}
                        value={SubscriptionStore.showData.pending}
                        icon="check"
                      >
                        Pending
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model="subscriptions"
                        field="automatic_renewal"
                        id={SubscriptionStore.showData.id}
                        value={SubscriptionStore.showData.automaticRenewal}
                        icon="check"
                      >
                        Automatic Renewal
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model="subscriptions"
                        field="one_time_payment"
                        id={SubscriptionStore.showData.id}
                        value={SubscriptionStore.showData.oneTimePayment}
                        icon="check"
                      >
                        One Time Payment
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Transaction Id</legend>
                    <InPlaceText
                      id={SubscriptionStore.showData.id}
                      field="transaction_id"
                      model="subscriptions"
                      defaultValue={SubscriptionStore.showData.transactionId}
                      afterChange={this.loadSubscription}
                    />
                    {
                      SubscriptionStore.showData.paymentMethod === 'stripe' &&
                        <a
                          href={`https://dashboard.stripe.com/subscriptions/${SubscriptionStore.showData.transactionId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            style={{marginTop: 10}}
                          >
                            View on Stripe
                          </Button>
                        </a>
                    }
                    {
                      SubscriptionStore.showData.paymentMethod === 'paypal' &&
                        <a
                          href={`https://www.paypal.com/cgi-bin/webscr?cmd=_profile-recurring-payments&encrypted_profile_id=${SubscriptionStore.showData.transactionId}&return_to=txn_details_new`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{marginTop: 10}}
                        >
                          <Button
                            style={{marginTop: 10}}
                          >
                            View on Paypal
                          </Button>
                        </a>
                    }
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Original Transaction Id</legend>
                    <InPlaceText
                      id={SubscriptionStore.showData.id}
                      field="original_transaction_id"
                      model="subscriptions"
                      defaultValue={SubscriptionStore.showData.originalTransactionId}
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Merged Transaction Id</legend>
                    <InPlaceText
                      id={SubscriptionStore.showData.id}
                      field="merged_transaction_id"
                      model="subscriptions"
                      defaultValue={SubscriptionStore.showData.mergedTransactionId}
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Stripe Customer Id</legend>
                    <InPlaceText
                      id={SubscriptionStore.showData.id}
                      field="stripe_customer_id"
                      model="subscriptions"
                      defaultValue={SubscriptionStore.showData.stripeCustomerId}
                      afterChange={this.loadSubscription}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Users</legend>
                    {
                      SubscriptionStore.hasShowData && SubscriptionStore.showData.deletedUser &&
                        <Deleted>
                          This subscription has a deleted user account.
                        </Deleted>
                    }
                    {
                      SubscriptionStore.hasShowData &&
                        <UserSubscriptionCreate
                          subscriptionId={SubscriptionStore.showData.id}
                          afterUpdate={this.loadSubscription}
                        />
                    }
                    <table
                      className="table-wrapper"
                      style={{marginTop: 10}}
                    >
                      <thead>
                        <tr>
                          <th
                            width="80"
                          />
                          <th>Name</th>
                          <th>Email</th>
                          {
                            (SubscriptionStore.showData.paymentMethod === 'ios' || SubscriptionStore.showData.paymentMethod === 'android') &&
                              <th />
                          }
                          <th
                            width="50"
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {
                          SubscriptionStore.showData.users.map(item => {
                            return (
                              <tr
                                key={item.id}
                              >
                                <td>
                                  <Link
                                    to={{name: AppUI.siteIsDefault ? 'builder.users.edit' : 'creator.groups.users.edit', params: {groupId: this.props.params.groupId, userId: item.id}}}
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                {
                                  (SubscriptionStore.showData.paymentMethod === 'ios' || SubscriptionStore.showData.paymentMethod === 'android') &&
                                    <td>
                                      <a
                                        href={`https://app.revenuecat.com/customers/87c9152a/${item.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Button>
                                          View on RevenueCat
                                        </Button>
                                      </a>
                                    </td>
                                }
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.onDeleteUser(item.id)}
                                    message="Remove user from subscription?"
                                    className="fa-block right"
                                  />
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Payments</legend>
                    {
                      this.showPaymentsUpdatedMessage &&
                        <Alert textAlign="center">
                          Payments fetched.
                        </Alert>
                    }
                    <PaymentIndex
                      subscriptionId={this.props.params.subscriptionId}
                      afterUpdatePayment={this.loadSubscription}
                      store={SubscriptionPaymentStore}
                      payments={SubscriptionPaymentStore.indexData}
                    />
                    {
                      this.showPaymentsButton &&
                        <Button
                          style={{marginTop: 10}}
                          onClick={this.updatePayments}
                        >
                          Fetch Payments
                        </Button>
                    }
                    {
                      !this.showPaymentsButton && ['paypal', 'stripe'].indexOf(SubscriptionStore.showData.paymentMethod) !== -1 &&
                        <Alert
                          margin="20px 0 0 0"
                        >
                          You need a Stripe subscription ID and Stripe customer ID or a Paypal agreement ID to fetch payments.
                        </Alert>
                    }
                  </fieldset>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

export default ErrorBoundary(SubscriptionEdit);

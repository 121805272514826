import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { VocabWordWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI, DeckUI } from '@seedlang/state';
import Text from 'components/text';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import Image from 'components/image';
import { isPresent } from '@seedlang/utils';
import { LanguageIcon, ChatOutlineIcon, TableIcon } from '@seedlang/icons';
import Tabs from 'components/tabs';
import VocabSentences from 'components/vocab/vocab_sentences';
import TranslationComments from 'components/comment/translation_comments';
import ConjugationTable from 'components/conjugation_table';
import autobind from 'autobind-decorator';
import ReviewToggle from 'components/review/review_toggle';
import Spinner from 'components/spinner';
import { Link } from 'react-router';
import SourceListWithInput from 'components/source/source_list_with_input';
import Frequency from 'components/vocab/frequency';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import Modal from 'components/modal';
import SentenceSubmissionIndex from 'components/sentence_submission/sentence_submission_index';
import WordCardRow from 'components/word_card/word_card_row';
import WordCardTitle from 'components/word_card/word_card_title';
import WordCardRowExpanded from 'components/word_card/word_card_row_expanded';
import VocabLabels from "components/vocab/vocab_labels";
import LevelTag from "components/vocab/level_tag";
import { noop, orderBy, some } from "lodash";
import RelatedWords from "components/vocab/related_words";

const TableWrapper = styled.div`
  margin: 10px 0 0 0;
`;

const Table = styled.div`
  padding: ${(props) => props.padding};
  ${flexCenterColumn()}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${props => props.padding || "0 10px"};
  margin: ${(props) => props.margin};
`;

const TranslationWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const LanguageIconWrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
`;

const LinkedWord = styled.span`
  border-bottom: ${props => props.noBorder ? '' : '2px solid #b5b5b5'};
  cursor: pointer;
`;

@observer
class VocabWordInfoList extends React.Component {
  @observable showTriviaQuestionModal = false;

  componentDidMount() {
    document.getElementById('top').scrollIntoView();
  }

  @computed get fontSize() {
    return AppUI.layout.isMobile ? '13px' : '16px';
  }

  @computed get lineHeight() {
    return AppUI.layout.isMobile ? '17px' : '22px';
  }

  @computed get targetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.targetTextWithGender;
    } else {
      return this.props.word.targetTextWithDefiniteArticle
    }
  }

  @computed get pluralTargetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.pluralNounsStringWithoutDefiniteArticle;
    } else {
      return this.props.word.pluralNounsString;
    }
  }

  @computed get genderVariantTargetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.genderVariant.targetTextWithGender;
    } else {
      return this.props.word.genderVariant.targetTextWithDefiniteArticle
    }
  }

  render() {
    return (
      <TableWrapper
        isMobile={AppUI.layout.isMobile}
      >
        <Table
          padding="10px 0"
        >
          <Row
            margin="5px 0 5px 0"
          >
            <LanguageIconWrapper
              width="25px"
            >
              <LanguageIcon
                languageId={this.props.word.languageId}
              />
            </LanguageIconWrapper>
            <TranslationWrapper>
              <Text
                bold
                fontSize="22px"
                lineHeight="26px"
                style={{whiteSpace: "nowrap"}}
              >
                {this.targetText}{this.props.word.pluralRoot ? ' (pl.)' : ''}
              </Text>
              {
                isPresent(this.props.word.genderVariant) && this.genderVariantTargetText !== this.props.word.targetText &&
                <Text
                  fontSize="22px"
                  lineHeight="26px"
                  style={{whiteSpace: "nowrap"}}
                >
                  ,&nbsp;
                </Text>
              }
              {
                isPresent(this.props.word.genderVariant) && this.genderVariantTargetText !== this.props.word.targetText &&
                  <Text
                    fontSize="22px"
                    lineHeight="26px"
                    style={{whiteSpace: "nowrap"}}
                    className={this.props.word.genderVariant.root ? 'underline' : ''}
                    onClick={this.props.word.genderVariant.root ? () => this.props.onClickWord(this.props.word.genderVariant) : noop}
                  >
                    <LinkedWord>
                      {this.genderVariantTargetText}{this.props.word.genderVariant.pluralRoot ? ' (pl.)' : ''}
                    </LinkedWord>
                  </Text>
              }
            </TranslationWrapper>
          </Row>
          {
            this.props.showPlural && !this.props.word.pluralRoot &&
            <Row
              margin="5px 0 5px 0"
            >
              <LanguageIconWrapper
                width="25px"
              >
                <LanguageIcon
                  languageId={this.props.word.languageId}
                />
              </LanguageIconWrapper>
              <TranslationWrapper>
                <Text
                  center
                  fontSize={this.fontSize}
                  lineHeight={this.lineHeight}
                >
                  {this.pluralTargetText}{' (pl.)'}
                </Text>
              </TranslationWrapper>
            </Row>
          }
          {
            this.props.showNoPlural &&
            <Row
              margin="5px 0 5px 0"
            >
              <TranslationWrapper>
                <Text
                  center
                  italic
                  margin="0 0 0 25px"
                  fontSize={this.fontSize}
                  lineHeight={this.lineHeight}
                >
                  No Plural
                </Text>
              </TranslationWrapper>
            </Row>
          }
          <SourceListWithInput
            word={this.props.word}
            background="#FFF"
            fontSize={this.fontSize}
            lineHeight={this.lineHeight}
            showInput={this.props.showSourceInput}
            afterSubmitSourceInput={this.props.afterSubmitSourceInput}
            padding="0 5px 5px 5px"
            user={AppUI.user}
          />
        </Table>
        {
          isPresent(this.props.word.synonymAssociations) && some(this.props.word.synonymAssociations.map(item => item.synonym?.publicInVocab)) &&
            <RelatedWords
              title='Synonyms'
              referenceWord={this.props.word}
              words={this.props.word.publicSynonyms}
              onClickWord={this.props.onClickWord}
            />
        }
        {
          isPresent(this.props.relatedWords) &&
            <RelatedWords
              title='Related Words'
              referenceWord={this.props.word}
              words={this.props.relatedWords}
              onClickWord={this.props.onClickWord}
            />
        }
      </TableWrapper>
    );
  }
}

export default VocabWordInfoList;

import React from 'react';
import { observer } from 'mobx-react';
import { SettingsStreakRepairWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import Button from 'components/button/button';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Link } from 'react-router';
import { CrownIcon } from '@seedlang/icons';
import Text from 'components/text';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const Section = styled.div`
  ${flexCenterColumn()}
  .crown-icon {
    svg {
      margin: 0 5px 0 0;
    }
  }
`;

const StreakRepair = styled.div`
  width: 100%;
`;

const Remaining = styled.span`
  background: ${Theme.green};
  color: white;
  font-weight: bold;
  padding: 2px 8px;
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const NoRepair = styled.div`
  text-align: left;
  width: 100%;

`;

@observer
class SettingsStreakRepair extends React.Component {

  render() {
    return (
      <Wrapper>
        <h3>Streak Repair</h3>
        {
          this.props.showStreakUpdatedMessage &&
            <Alert
              width="100%"
              textAlign="center"
            >
              Your streak is updated and is now set to {AppUI.user.currentStreakCount} day{AppUI.user.currentStreakCount === 1 ? null : 's'}.
            </Alert>
        }
        {
          !AppUI.user.isMember &&
            <Section>
              <Alert
                textAlign="center"
                width="100%"
                borderRadius="5px"
                margin="0 0 20px 0"
              >
                This feature is only available to Seedlang Pros.
              </Alert>
              <Link
                to={{name: 'memberships.index'}}
              >
                <Button>
                  <CrownIcon
                    margin="0 5px 0 0"
                  />
                  Become a Pro
                </Button>
              </Link>
            </Section>
        }
        {
          AppUI.user.isMember &&
            <Section>
              {
                !AppUI.user.streakRepairIsAvailable &&
                  <NoRepair>
                    <div>
                      <b>You will have another streak repair available next month.</b>
                    </div>
                    <div>
                      Your last streak repair: {AppUI.user.lastStreakRepairedAt?.formattedDateWithYear}
                    </div>
                  </NoRepair>
              }
              {
                AppUI.user.streakRepairIsAvailable &&
                  <StreakRepair>
                    <Text
                      lineHeight="24px"
                      margin="0 0 20px 0"
                    >
                      You have <Remaining>1</Remaining> streak repair remaining this month. A streak repair will not work if the day that was missed was more than 100 days ago.
                    </Text>
                    <Row>
                      <Button
                        onClick={this.props.onRepairStreak}
                      >
                        Repair Streak
                      </Button>
                    </Row>
                  </StreakRepair>

              }
            </Section>
        }
      </Wrapper>
    );
  }
}

export default SettingsStreakRepairWrapper(SettingsStreakRepair);

import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, toJS} from 'mobx';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Spinner from 'components/spinner';
import moment from 'moment';
import { each, keys } from 'lodash';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { Constants } from '@seedlang/constants';
import { isPresent, isBlank, kFormatter } from '@seedlang/utils';
import { LanguageIcon } from '@seedlang/icons';
import AnalyticsDateModal from 'components/analytics/analytics_date_modal';
import AnalyticsDayAnalysis from 'components/analytics/analytics_day_analysis';
import AnalyticsList from 'components/analytics/analytics_list';
import Modal from 'components/modal';
import InPlaceSelect from 'components/form/in_place_select';
import { buttonColumnWidth, dateColumnWidth } from 'components/analytics/analytics_list_row';

const DISPLAY_DASHES_IF_ZERO = ['dailyActiveUsersCount', 'weeklyActiveUsersCount', 'monthlyActiveUsersCount'];

const Header = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align: self-start;
  select {
    height: 40px!important;
    border: 1px solid #FFF;
  }
`;

const CalendarWrapper = styled.div`
  ${flexCenterColumn()}
  width: ${(props) => props.width};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  color: #333;
  width: 40px;
  height: 40px;
  &:hover {
    background: #e0e0e0;
  }
  border-radius: 50px;
  font-size: ${props => props.fontSize};
`;

const DateString = styled.div`
  &:hover {
    background: ${props => props.hoverBackground};
  }
  cursor: ${props => props.cursor};
  font-size: 22px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 40px;
`;

const LanguageIconWrapper = styled.div`
  display: flex;
  background: ${props => props.background};
  padding: 10px;
  border-radius: 5px;
  margin: 0 0 0 5px;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  font-weight: bold;
`;

const Stats = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const Tab = styled.span`
  display: block;
  padding: 0 12px;
  border-radius: 8px 8px 0 0;
  font-size: 14px;
  background: ${(props) => props.isSelected ? 'white' : '#CCC'};
  cursor: pointer;
`


@observer
class Analytics extends React.Component {
  @observable loading = true;
  @observable todaysDate;
  @observable currentMonth;
  @observable currentYear;
  @observable currentDay;
  @observable counts = {};
  @observable stats = {};
  @observable selectedDay;
  @observable selectedMonth;
  @observable selectedYear;
  @observable selectedTabIndex = 0;
  @observable whiteLabel = false;
  @observable languageIds;
  @observable singleLanguage;
  @observable showDateModal = false;
  @observable activeLanguageIds;
  @observable viewType;
  @observable timePeriod;
  @observable activeFields;

  constructor(props) {
    super(props);
    this.whiteLabel = !AppUI.siteIsDefault;
    this.singleLanguage = !AppUI.siteIsDefault;
    this.viewType = this.getCookieValue('viewType') || 'calendar';
    this.timePeriod = this.getCookieValue('timePeriod') || 'daily';
    this.languageIds = this.whiteLabel ? (this.singleLanguage ? [AppUI.site?.languageId] : ['AR', 'CS', 'PT2']) : ['DE', 'FR', 'ES'];
    this.activeLanguageIds = isPresent(this.getCookieValue('activeLanguageIds')) ? this.getCookieValue('activeLanguageIds').split(",") : this.languageIds;
    this.todaysDate = new Date();
    this.currentMonth = parseInt(moment(this.todaysDate).format('M'), 10);
    this.currentYear = parseInt(moment(this.todaysDate).format('Y'), 10);
    this.currentDay = parseInt(moment(this.todaysDate).format('D'), 10);
    if (window.location.href.match('month')) { // for some reason props.params is not including month and date?
      const ary = window.location.href.split("/year/")[1].split("/month/");
      this.selectedMonth = parseInt(ary[1], 10);
      this.selectedYear = parseInt(ary[0], 10);
    } else {
      this.selectedMonth = this.currentMonth;
      this.selectedYear = this.currentYear;
    }
    (async () => {
      this.loading = true;
      await this.loadMembershipTypes();
      this.activeFields = isPresent(this.getCookieValue('activeFields')) ? this.getCookieValue('activeFields').split(",") : keys(this.possibleFields);
      this.getAnalyticsIndex();
    })();
  }

  @computed get possibleTabs() {
    if (!this.whiteLabel) {
      return null;
    }

    return [
      {
        id: 'overview',
        name: 'Overview',
      },
      {
        id: 'tiers',
        name: 'Tiers',
      },
      {
        id: 'active',
        name: 'Active Users',
      }
    ]
  }

  @computed get selectedTab() {
    return this.possibleTabs ? this.possibleTabs[this.selectedTabIndex] : null;
  }

  @computed get possibleFields() {
    let fields = {}

    if (AppUI.siteIsDefault) {
      fields.newUsersCount = { name: 'Users', tooltip: 'New users created on this date.', showBorder: true }
      fields.newSubscriptionsCount = { name: 'Subs', tooltip: 'New subscriptions created on this date.'}
      fields.cancellationsCount = { name: 'Canc', tooltip: 'New Cancellations' };
      fields.newSubscriptionsForNewUsersCount = { name: 'All Subs', tooltip: 'Subscriptions created by new users who joined on this date.', showBorder: true}
      fields.conversionPercentage = { name: 'Cnv%', tooltip: 'Conversion percentage for users who joined on this date.' };
      fields.revenue = { name: 'Revenue', tooltip: 'Revenue for users who joined on this date.' };
      fields.average = { name: 'Avg Order', tooltip: 'Average purchase order for users who joined on this date.' };
      fields.subscriptionsOldPricing = { name: 'Old Price', tooltip: 'Subscriptions Old Pricing', showBorder: true };
      fields.conversionPercentageOldPricing = { name: 'Cnv%', tooltip: 'Conversion Percentage Old Pricing' };
      fields.revenueOldPricing = { name: 'Revenue', tooltip: 'Conversion Percentage Old Pricing' };
      fields.averageOldPricing = { name: 'Avg Order', tooltip: 'Average Purchase Order Old Pricing' };
      fields.subscriptionsNewPricing = { name: 'New Price', tooltip: 'Subscriptions New Pricing', showBorder: true };
      fields.conversionPercentageNewPricing = { name: 'Cnv%', tooltip: 'Conversion Percentage New Pricing' };
      fields.revenueNewPricing = { name: 'Revenue', tooltip: 'Conversion Percentage New Pricing' };
      fields.averageNewPricing = { name: 'Avg Order', tooltip: 'Average Purchase Order New Pricing' };
      fields.subscriptionsWeb = { name: 'Web', tooltip: 'Subscriptions Web', showBorder: true };
      fields.conversionPercentageWeb = { name: 'Cnv%', tooltip: 'Conversion Percentage by Web' };
      fields.revenueWeb = { name: 'Revenue', tooltip: 'Conversion Percentage By Web' };
      fields.averageWeb = { name: 'Avg Order', tooltip: 'Average Purchase Order By Web' };
      fields.subscriptionsMobile = { name: 'Mobile', tooltip: 'Subscriptions Mobile', showBorder: true };
      fields.conversionPercentageMobile = { name: 'Cnv%', tooltip: 'Conversion Percentage by Mobile' };
      fields.revenueMobile = { name: 'Revenue', tooltip: 'Conversion Percentage By Mobile' };
      fields.averageMobile = { name: 'Avg Order', tooltip: 'Average Purchase Order By Mobile' };
      fields.dailyActiveUsersCount = { name: 'DAU', tooltip: 'Daily Active Users', showBorder: true };
      fields.weeklyActiveUsersCount = { name: 'WAU', tooltip: 'Weekly Active Users' };
      fields.monthlyActiveUsersCount = { name: 'MAU', tooltip: 'Monthly Active Users' };
      fields.firstDeckAttemptedCount = { name: '1stD Att', tooltip: '1st Deck Attempted', showBorder: true };
      fields.firstDeckCompletedCount = { name: '1stD Compl', tooltip: '1st Deck Completed' };
      fields.firstDeckCompletedPercentage = { name: '1st Deck Compl%', tooltip: '1st Deck Completed Percentage' };
    }
    else {
      // Overview tab
      fields.newSubscriptionsCount = { name: 'New Subscriptions', tabId: 'overview' }
      fields.cancellationsCount = { name: 'New Cancellations', tabId: 'overview' };
      fields.totalSubscriptionsCount = { name: 'Total Active Subscriptions', tabId: 'overview' };

      // Tiers tab
      const membershipTypes = AppUI.membershipTypeStore.indexData.slice().sort((a, b) => a.position - b.position);
      membershipTypes.forEach(({id, name, position}) => {
        fields[`newSubscriptionsCountTier${position}`] = {
          name: `${shortenTierName(name)} (new)`,
          tooltip: `${name} – New Subscriptions`,
          tabId: 'tiers',
        }
        fields[`totalSubscriptionsCountTier${position}`] = {
          name: `${shortenTierName(name)} (total)`,
          tooltip: `${name} – Total Active Subscriptions`,
          tabId: 'tiers',
        }
      })

      // Active Users tab
      const activeUsersField = (period, periodLy) => (
        {
          name: `${periodLy} Active Users`,
          tooltip: `Number of people per ${period} that interacted with a worksheet`,
          tabId: 'active',
        }
      )
      fields.dailyActiveUsersCount = activeUsersField('day', 'Daily');
      fields.weeklyActiveUsersCount = activeUsersField('week', 'Weekly');
      fields.monthlyActiveUsersCount = activeUsersField('month', 'Monthly');
    }

    return fields;
  }

  @computed get cookiePrefix() {
    return this.whiteLabel ? "analytics-white-label" : "analytics-default";
  }

  @computed get orderedActiveLanguageIds() {
    return this.languageIds.filter((item) => {
      return this.activeLanguageIds.includes(item);
    });
  }

  @computed get selectedDayWithZero() {
    return parseInt(this.selectedDay, 10) < 10 ? '0' + this.selectedDay : this.selectedDay;
  }

  @computed get selectedMonthWithZero() {
    return parseInt(this.selectedMonth, 10) < 10 ? '0' + this.selectedMonth : this.selectedMonth;
  }

  @computed get selectedDateString() {
    return `${this.selectedYear}-${this.selectedMonthWithZero}-${this.selectedDayWithZero}`;
  }

  @computed get fromDate() {
    if (this.timePeriod === 'daily') {
      return new Date(this.selectedYear, this.selectedMonth - 1, 1);
    } else {
      return new Date(this.selectedYear, 0, 1);
    }
  }

  @computed get toDate() {
    if (this.timePeriod === 'daily') {
      return new Date(this.selectedYear, this.selectedMonth, 1);
    } else {
      return new Date(this.selectedYear, 11, 31);
    }
  }

  @computed get startOfSelectedMonth() {
    return moment([this.selectedYear, this.selectedMonth - 1]).startOf('month');
  }

  @computed get endOfSelectedMonth() {
    return moment([this.selectedYear, this.selectedMonth - 1]).endOf('month');
  }

  @computed get monthIsCurrent() {
    return this.selectedMonth === this.currentMonth && this.selectedYear === this.currentYear;
  }

  @computed get endOfLastMonth() {
    return moment(this.fromDate).subtract(1, 'month').endOf('month');
  }

  @computed get activeFieldsWithDetails() {
    return keys(this.possibleFields).filter(fieldKey => this.activeFields.indexOf(fieldKey) !== -1).map(fieldKey => [fieldKey, this.possibleFields[fieldKey]]);
  }

  @computed get visibleFieldsWithDetails() {
    return this.activeFieldsWithDetails.filter(([fieldKey, fieldDetails]) => fieldDetails.tabId === null || fieldDetails.tabId === this.selectedTab?.id);
  }

  @autobind afterGetAnalyticsIndex(resp) {
    this.loading = false;
    if (isBlank(resp) && !Array.isArray(resp)) { return }
    this.counts = resp;
    if (this.whiteLabel && this.singleLanguage) {
      each(this.counts, item => {
        AppUI.membershipTypeStore.indexData.sort((a, b) => a.position - b.position).forEach(({ id, name, position }) => {
          item[`newSubscriptionsCountTier${position}`] = item.newSubscriptionsCountsByTier.find(({membershipTypeId}) => membershipTypeId === id)?.count; 
          item[`totalSubscriptionsCountTier${position}`] = item.totalSubscriptionsCountsByTier.find(({membershipTypeId}) => membershipTypeId === id)?.count;
        })
        delete item.newSubscriptionsCountsByTier;
        delete item.totalSubscriptionsCountsByTier;
      })
    }
    each(this.languageIds, (languageId) => {
      this.stats[languageId] = {};
      each(['newUsersCount', 'newSubscriptionsCount'], (type) => {
        this.stats[languageId][type] = {max: 0, sum: 0, count: 0, average: 0};
        this.counts.filter(item => item.languageId === languageId).map((result) => {
          this.stats[languageId][type]['count'] += 1;
          this.stats[languageId][type]['sum'] += result[type];
          this.stats[languageId][type]['average'] = parseInt(this.stats[languageId][type]['sum'] / this.stats[languageId][type]['count'], 10);
        })
      })
    })
  }

  @autobind incrementDate() {
    if (this.timePeriod === 'daily') {
      this.selectedMonth += 1;
      if (this.selectedMonth > 12) {
        this.selectedMonth = 1;
        this.selectedYear += 1;
      }
    } else {
      this.selectedYear += 1;
    }
    if (AppUI.routeStore.routeName.match('creator')) {
      AppUI.routeStore.routeToNamed('creator.exercises.analytics', {groupId: this.props.params.groupId, month: this.selectedMonth, year: this.selectedYear})
    } else {
      AppUI.routeStore.routeToNamed('builder.dashboard.analytics_with_month', {month: this.selectedMonth, year: this.selectedYear})
    }
    this.getAnalyticsIndex();
  }

  @autobind decrementDate() {
    if (this.timePeriod === 'daily') {
      this.selectedMonth -= 1;
      if (this.selectedMonth < 1) {
        this.selectedMonth = 12;
        this.selectedYear -= 1;
      }
    } else {
      this.selectedYear -= 1;
    }
    if (AppUI.routeStore.routeName.match('creator')) {
      AppUI.routeStore.routeToNamed('creator.exercises.analytics', {groupId: this.props.params.groupId, month: this.selectedMonth, year: this.selectedYear})
    } else {
      AppUI.routeStore.routeToNamed('builder.dashboard.analytics_with_month', {month: this.selectedMonth, year: this.selectedYear})
    }
    this.getAnalyticsIndex();
  }

  @autobind getAnalyticsIndex() {
    this.loading = true;
    AppUI.analyticStore.getIndex({queryStrings: {white_label: this.whiteLabel}, filters: {
      time_period: this.timePeriod,
      date: `RANGE[${this.fromDate.toISOString()},${this.toDate.toISOString()}]`,
    }}, this.afterGetAnalyticsIndex);
  }

  @autobind loadMembershipTypes() {
    return new Promise((resolve) => {
      if (this.whiteLabel && this.singleLanguage && AppUI.membershipTypeStore.indexData.length === 0) {
        AppUI.membershipTypeStore.getIndex({}, (resp) => {
          AppUI.membershipTypeStore.afterGetIndex(resp);
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  }

  @autobind onSelectDate(year, month) {
    this.selectedYear = year;
    this.selectedMonth = month;
    this.showDateModal = false;
    AppUI.routeStore.routeToNamed('builder.dashboard.analytics_with_month', {month: this.selectedMonth, year: this.selectedYear})
    this.getAnalyticsIndex();
  }

  @autobind onSelectDay(day) {
    this.selectedDay = day;
  }

  @autobind onCloseAnalysisModal() {
    this.selectedDay = null;
    AppUI.userSubscriptionStore.clearIndexData();
    AppUI.subscriptionStore.clearIndexData();
  }

  @autobind toggleActiveLanguageId(languageId) {
    if (this.activeLanguageIds.indexOf(languageId) === -1) {
      this.activeLanguageIds.push(languageId);
    } else {
      this.activeLanguageIds = this.activeLanguageIds.filter(item => item !== languageId);
    }
    this.setCookieValue('activeLanguageIds', this.activeLanguageIds.join(","));
  }

  @autobind onSetViewType(viewType) {
    this.viewType = viewType;
    this.setCookieValue('viewType', this.viewType);
  }

  @autobind onSetTimePeriod(timePeriod) {
    this.timePeriod = timePeriod;
    this.setCookieValue('timePeriod', this.timePeriod);
    this.getAnalyticsIndex();
  }

  @autobind onSetActiveFields(field) {
    if (this.activeFields.indexOf(field) === -1) {
      this.activeFields.push(field);
    } else {
      this.activeFields = this.activeFields.filter(item => item !== field);
    }
    this.setCookieValue('activeFields', this.activeFields.join(","));
  }

  @autobind getCookieValue(field) {
    return AppUI.getCookieValue(`${this.cookiePrefix}-${field}`);
  }

  @autobind setCookieValue(field, value) {
    AppUI.setCookieValue(`${this.cookiePrefix}-${field}`, value);
  }

  @autobind setSelectValue(field, value) {
    this[field] = value;
    this.setCookieValue(field, value);
  }

  render() {
    return (
      <Wrapper>
        {
          this.loading &&
            <Spinner />
        }
        {
          this.showDateModal &&
            <Modal
              width="400px"
              height="250px"
              hideCloseIcon
              onCloseModal={() => this.showDateModal = false}
            >
              <AnalyticsDateModal
                onSelectDate={this.onSelectDate}
                year={this.selectedYear}
              />
            </Modal>
        }
        {
          this.selectedDay && isPresent(this.counts) &&
            <Modal
              width="800px"
              height="700px"
              onCloseModal={this.onCloseAnalysisModal}
              hideCloseIcon
            >
              <AnalyticsDayAnalysis
                whiteLabel={this.whiteLabel}
                languageIds={this.languageIds}
                activeLanguageIds={this.activeLanguageIds}
                singleLanguage={this.singleLanguage}
                dateString={this.selectedDateString}
                stats={this.stats}
                counts={this.counts}
                month={this.selectedMonth}
                year={this.selectedYear}
                day={this.selectedDay}
              />
            </Modal>
        }
        {
          !this.loading &&
            <CalendarWrapper width={AppUI.siteIsDefault ? '1000px' : '100%'}>
              <Header>
                <IconWrapper
                  fontSize="28px"
                  onClick={this.decrementDate}
                >
                  <i className='fa fa-angle-left' />
                </IconWrapper>
                <IconWrapper
                  fontSize="28px"
                  onClick={this.incrementDate}
                >
                  <i className='fa fa-angle-right' />
                </IconWrapper>
                <InPlaceSelect
                  margin="0 5px 0 0"
                  options={[['daily', 'Daily'], ['weekly', 'Weekly'], ['monthly', 'Monthly']]}
                  onChange={(val) => this.onSetTimePeriod(val)}
                  value={this.timePeriod}
                />
                {
                  this.timePeriod === 'daily' &&
                    <DateString
                      hoverBackground="#e0e0e0"
                      cursor="pointer"
                      onClick={() => this.showDateModal = true}
                    >
                      {Constants.MONTHS[this.selectedMonth - 1]} {this.selectedYear}
                    </DateString>
                }
                {
                  this.timePeriod !== 'daily' &&
                    <DateString
                      cursor="default"
                    >
                      {this.selectedYear}
                    </DateString>
                }
                <Stats>
                  {
                    !this.singleLanguage && this.languageIds.map(item => {
                      return (
                        <LanguageIconWrapper
                          key={item}
                          onClick={() => this.toggleActiveLanguageId(item)}
                          background={this.activeLanguageIds.indexOf(item) === -1 ? '#CCC' : '#FFF'}
                        >
                          <LanguageIcon
                            languageId={item}
                            width="20px"
                            height="20px"
                            margin="0 5px 0 0"
                          />
                          {
                            this.stats[item] && this.stats[item][this.statType] &&
                              <Count>
                                {kFormatter(this.stats[item][this.statType][this.currentStat])}
                              </Count>
                          }
                        </LanguageIconWrapper>
                      )
                    })
                  }
                </Stats>


                {
                  this.possibleTabs && (
                    <TabsContainer>{
                      this.possibleTabs.map((tab, tabIndex) => (
                        <Tab key={tab.id} isSelected={this.selectedTabIndex === tabIndex} onClick={() => this.selectedTabIndex = tabIndex}>
                          {tab.name}
                        </Tab>
                      ))
                    }</TabsContainer>
                  )
                }
              </Header>
              {
                isPresent(this.counts) &&
                  <AnalyticsList
                    stats={this.stats}
                    counts={this.counts}
                    timePeriod={this.timePeriod}
                    startOfSelectedMonth={this.startOfSelectedMonth}
                    endOfLastMonth={this.endOfLastMonth}
                    endOfSelectedMonth={this.endOfSelectedMonth}
                    selectedMonth={this.selectedMonth}
                    selectedYear={this.selectedYear}
                    languageIds={this.orderedActiveLanguageIds}
                    onSelectDay={this.onSelectDay}
                    statFields={this.visibleFieldsWithDetails}
                    currentDay={this.currentDay}
                    currentMonth={this.currentMonth}
                    currentYear={this.currentYear}
                    displayDashesIfZero={DISPLAY_DASHES_IF_ZERO}
                    allowClickHeader={!this.whiteLabel}
                    singleLanguage={this.singleLanguage}
                    dataColumnWidth={this.whiteLabel && this.singleLanguage ? Math.trunc((999 - buttonColumnWidth - dateColumnWidth) / this.visibleFieldsWithDetails.length) : 100}
                  />
                }
            </CalendarWrapper>
        }
      </Wrapper>
    );
  }
}

function shortenTierName(name) {
  return name.replace(/ Membership$/i, '').replaceAll(/ and /g, '&');
}

export default Analytics;
